<nb-card class="bg-light bg-gradient">
  <nb-card-body>
    <div class="d-flex justify-content-end">
      <img (click)="closeInitialAlert()" class="close-gray" src="assets/imgs/close-thin-gray.svg">
    </div>
    <div class="p-5">
      <h3 class="d-flex title-modal justify-content-center align-items-center">
        {{ title }}
      </h3>
      <div class="mt-4">
        {{ text }}
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button nbButton status="info" size="large" shape="round" hero (click)="goToDashboard()">{{ 'Revisar ahora' }}</button>
      </div>   
    </div>
  </nb-card-body>
</nb-card>