import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  ProfilesGroupedByPersonalityVarDto,
  ProfilesGroupedByVulnerabilityLevelDto,
} from "../../../../../services/api/models";

@Component({
  selector: "ngx-big-graph",
  templateUrl: "./big-graph.component.html",
  styleUrls: ["./big-graph.component.scss"],
})
export class BigGraphComponent implements OnInit, AfterViewInit {
  @Input() graphData: Array<
    ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto
  >;
  @Input() colorScheme;
  @Input() title: string;
  @Input() graphType: string;
  @Input() isVulnerability: boolean;
  @Input() isLinear: boolean;
  @Input() monthMode: boolean = true;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit(): void {}

  closeModalInfo() {
    this.closeModal.emit();
  }
}
