<div class="d-flex justify-content-between">
  <div class="d-flex justify-content-between mt-3 align-items-center w-100">
    <span>Total de registros: {{ pagination.page.totalElements }}</span>
  </div>
  <div class="d-flex justify-content-end mt-3 align-items-center w-100">
    <span class="pr-2">{{ 'Ir a '}}</span>
    <nb-select filled shape="round" [status]="'info'" class="pr-2" [(selected)]="pagination.page.pageNumber" (selectedChange)="onPageSelect($event)">
      <nb-option *ngFor="let page of pagination.getPageNumbers()" [value]="page - 1">{{ page }}</nb-option>
    </nb-select>
    <nb-icon class="pag-icon" status="info" (click)="prevPage()" icon="arrow-ios-back-outline"></nb-icon>
    <div class="d-flex justify-content-center align-items-center">
      <span>{{ (pagination.page.pageNumber +1) + ' / ' + pagination.getPages()  }}</span>
    </div>
    <nb-icon class="pag-icon" status="info" (click)="nextPage()" icon="arrow-ios-forward-outline"></nb-icon>
  </div>
</div>
