<ngx-datatable class="material" [rows]="paginatedProfiles" [loadingIndicator]="false" [columns]="columns"
  [columnMode]="'force'" headerHeight="auto" rowHeight="auto" [reorderable]="false" [externalPaging]="true"
  [count]="pagination.page.totalElements" [offset]="pagination.page.pageNumber"
  [limit]="pagination.page.pageSize" (page)='onPageSelect($event.offset)'>
  <ngx-datatable-column [resizeable]="false"  
    *ngFor="let col of columns" [name]="col.name" [prop]="col.prop">
    <ng-template ngx-datatable-header-template let-column="column">
      {{ column.name }}
    </ng-template>
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-column="column">
      <span *ngIf="col.prop === 'status' && row.status === 'borrador'">
        <img class="available-status-size mr-2" src="assets/imgs/online.svg" alt="Estado">            
      </span>
      <span *ngIf="col.prop === 'status' && row.status === 'finalizada'">
        <img class="available-status-size mr-2" src="assets/imgs/offline.svg" alt="Estado">            
      </span>
      <span *ngIf="col.prop === 'status' && row.status === 'enviada'">
        <img class="available-status-size mr-2" src="assets/imgs/send.svg" alt="Estado">            
      </span>
      <span *ngIf="col.prop === 'status'"  class="font-weigth-bold">
        {{  getUpperCase(value)  }}
      </span>
      <span *ngIf="col.prop !== 'status'">
        {{ (column.prop === 'startDate' || column.prop === 'endDate') ? (value | date:'dd/MM/yyyy HH:mm:ss') : ( value ) }}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [resizeable]="false" [sortable]="false" [cellClass]="'d-flex justify-content-around'"
    [headerClass]="'d-flex justify-content-center'">
    <ng-template ngx-datatable-header-template let-column="column">
      <div class="d-flex">
        <span>
          Aceptar/Rechazar
        </span>
        <div class="d-flex justify-content-center align-items-center">
          <nb-actions [nbTooltip]="'No se permite rechazar o aceptar perfiles administradores'" [size]="'tiny'" >
            <nb-action icon="question-mark-circle-outline" ></nb-action>
          </nb-actions>
        </div>
      </div>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-column="column">
      <ng-container *ngIf="row?.role !== 'eve-admin'">
        <div *ngIf="userData && userData.email !== row.email"  class="d-flex gap">
          <button [nbTooltipStatus]="'info'" 
          [nbTooltip]="'Rechazar perfil'" *ngIf="isRowOnConfirmProfiles(row)" nbButton status="success" (click)="rejectProfile(row)" size="medium" shape="round">
            <nb-icon  icon="checkmark-outline" pack="eva"></nb-icon>
          </button>
          <button *ngIf="!isRowOnConfirmProfiles(row)" [nbTooltipStatus]="'info'" 
          [nbTooltip]="'Confirmar perfil'" nbButton status="danger" (click)="acceptProfile(row)" size="medium" shape="round">
            <nb-icon  icon="close-outline" pack="eva"></nb-icon>
          </button>    
        </div>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [resizeable]="false" [cellClass]="'d-flex justify-content-around'"
    [headerClass]="'d-flex justify-content-center'">
    <ng-template ngx-datatable-header-template let-column="column">
      Opciones
    </ng-template>
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-column="column">
      <ng-container *ngIf="row?.role !== 'eve-admin'">
        <div *ngIf="userData && userData.email !== row.email"  class="d-flex gap">
          <nb-actions size="small">
            <nb-action class="user-action">
              <nb-actions [size]="'small'">
                <nb-action [nbTooltipStatus]="'info'" 
                [nbTooltip]="'Editar'" icon="edit-2-outline" (click)="showEditModal(row)"></nb-action>
                <nb-action [nbTooltipStatus]="'info'" 
                [nbTooltip]="'Eliminar'" icon="trash-2-outline" (click)="deleteProfile(row)"></nb-action>
              </nb-actions>
            </nb-action>
          </nb-actions>
        </div>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-container *ngIf="profiles && profiles?.length > 0">
  <div class="d-flex justify-content-between">
    <div class="d-flex justify-content-between mt-3 align-items-center w-100">
      <span>Total de registros: {{ pagination.page.totalElements }}</span>
    </div>
    <div class="d-flex justify-content-end mt-3 align-items-center w-100">
      <span class="pr-2">{{ 'Ir a '}}</span>
      <nb-select filled shape="round" [status]="'info'" class="pr-2" [(selected)]="pagination.page.pageNumber" (selectedChange)="onPageSelect($event)">
        <nb-option *ngFor="let page of [].constructor(pagination.page.totalPages); let i = index" [value]="i">{{ i + 1 }}</nb-option>
      </nb-select>
      <nb-icon class="pag-icon" status="info" (click)="prevPage()" icon="arrow-ios-back-outline"></nb-icon>
      <div class="d-flex justify-content-center align-items-center">
        <span>{{ (pagination.page.pageNumber + 1) + ' / ' + pagination.page.totalPages }}</span>
      </div>
      <nb-icon class="pag-icon" status="info" (click)="nextPage()" icon="arrow-ios-forward-outline"></nb-icon>
    </div>
  </div>
</ng-container>

<ng-template #modalEdit let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body>
      <h2>{{isNewProfileModal ? 'Nuevo perfil' : 'Edición del perfil'}}</h2>
      <form [formGroup]="editionForm" (ngSubmit)="submitForm(ref, data)">
        <div class="form-control-group">
          <label class="label" for="input-email">{{'Email:'}}</label>
          <input nbInput
                 [shape]="'round'"
                 fullWidth
                 formControlName="email"
                 name="email"
                 id="input-email"
                 placeholder="Email">
        </div>
        <div class="form-control-group">
          <label class="label" for="input-name">{{'Nombre:'}}</label>
          <input nbInput
                 [shape]="'round'"
                 fullWidth
                 formControlName="name"
                 name="name"
                 id="input-name"
                 placeholder="Nombre">
        </div>
        <div class="form-control-group">
          <label class="label" for="input-surname">{{'Apellido:'}}</label>
          <input nbInput
                 [shape]="'round'"
                 fullWidth
                 formControlName="surname"
                 name="surname"
                 id="input-surname"
                 placeholder="Surname">
        </div>
        <div class="w-100 mt-3">
          <button nbButton fullWidth [shape]="'round'" [status]="'info'" type="submit">{{isNewProfileModal ? 'Añadir' : 'Editar'}}</button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>