import { NbMenuItem } from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: "Dashboard",
    icon:  "grid-outline",
    link: "/account/dashboard",
    data: { roles: ['eve-admin', 'eve-user'] },
    home: true,
  },
  {
    title: "Personalidad",
    icon: "person-done-outline",
    link: "/account/eve/personality",
    data: { roles: ['eve-user'] },
  },
  // {
  //   title: "Campo de visión",
  //   icon: "layout-outline",
  //   link: "/account/eve/personality",
  //   data: { roles: ['eve-user'] },
  // },
  {
    title: "EVE",
    icon: "radio-button-off-outline",
    link: "/account/eve",
    data: { roles: ['eve-user'] },
    hidden: false
  },
  {
    title: 'Campañas',
    icon: 'layers-outline',
    link: "/account/campaign",
    data: { roles: ['eve-admin'] },
    children: [
      {
        title: 'Campañas',
        link:'/account/campaign',
        data: { roles: ['eve-admin'] },
      },
      // {
      //   title: 'Conf. Email',
      //   link: '/account/campaign/email-conf',
      //   data: { roles: ['eve-admin'] },
      // },
      // {
      //   title: 'Grupos',
      //   link: '/account/campaign/groups',
      //   data: { roles: ['eve-admin'] },
      // },
      // {
      //   title: 'Template Manager',
      //   link: '/account/campaign/template-manager',
      //   data: { roles: ['eve-admin'] },
      // },
    ],
  },
  {
    title: "Usuarios",
    icon: "people-outline",
    link: "/account/users/resume",
    data: { roles: ['eve-admin'] },
  },
  {
    title: "Mis datos",
    icon: "person-outline",
    link: "/account/profile",
    data: { roles: ['eve-user'] },
  },
  {
    title: "Datos de mi empresa",
    icon: "clipboard-outline",
    link: "/account/profile",
    data: { roles: ['eve-admin'] },
  },
  // {
  //   title: "Contenido",
  //   icon: "book-open-outline",
  //   link: "/account/content",
  //   data: { roles: ['eve-admin'] },
  // },
];
