import { Injectable } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { CoreProvider } from "./core.provider";
import { WorkspaceModelDto } from "../api/models";
import { ConfirmWorkspaceModalComponent } from "../../main-module/account-module/components/confirm-workspace-modal/confirm-workspace-modal.component";
import { ComponentsService } from "./components.service";
import { firstValueFrom, take } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InitService {
  private dialogRef: NbDialogRef<any>;

  core: CoreProvider;
  userInitialised = false;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly componentsService: ComponentsService,
  ) {}

  initCore(core: CoreProvider) {
    this.core = core;
  }

  initUser() {
    if (this.userInitialised) return;
    this.userInitialised = true;
    this.checkAction();
    this.checkAdminWorkspaceApproval();

    // FIXME: Do not require everytime the user to login since there are even public pages, just subscribe to the user login and check action after login
    // if (userRole not eve-user and userRole not eve-admin) then Maybe redirect to page telling the user that he is not enabled to use eve platform, contact the administrator
    // this.core.keycloak.login();
  }

  async checkAction(uuidResource?: string): Promise<boolean | "error"> {
    return new Promise(async (resolve) => {
      if (this.core.keycloak.isLoggedIn()) {
        const userRoles = this.core.roles;
        const userData = await firstValueFrom(this.core.getUserData());
        // if (userRoles.includes("eve-user")) {
          try {
            if (uuidResource) {
              this.core.router.navigateByUrl(`/account/eve/${uuidResource}/resource`);
              resolve(true);
            }
            if (userData.requiredAction && userData.requiredAction !== "") {
              if (userData.requiredAction.includes("resource:")) {
                const uuid = this.getActionUuidString(userData.requiredAction);
                this.core.router.navigateByUrl(`/account/eve/${uuid}/resource`);
                resolve(true);
              }
            } else {
              if (environment.isDemo) {
                this.core.router.navigateByUrl(`/account/eve/end/public-results`);
                resolve(true);
              } else {
                resolve(false);
              }
            }
          } catch (error) {
            resolve("error");
            this.componentsService.showToast(
              "danger",
              "Hemos tenido un problema al comprobar las acciones requeridas. Recarga la pestaña por favor.",
              "Uf..."
            );
          }
        // }
      }
    });
  }

  checkAdminWorkspaceApproval() {
    if (this.core.roles.includes("eve-admin")) {
      this.core.api.workspace
        .workspaceControllerGetAllWorkspaceInfo()
        .subscribe({
          next: (res: WorkspaceModelDto) => {
            if (!res.approved) {
              this.openModal(ConfirmWorkspaceModalComponent, {
                workspace: res,
                hasBackdrop: true,
                closeOnBackdropClick: false,
                closeOnEsc: false,
              });
            }
          },
          error: (err) => {
            console.error(
              "Error al obtener la información del workspace, skipping ",
              err
            );
          },
        });
    }
  }

  openModal(component: any, data: any) {
    this.dialogRef = this.dialogService.open(component, {
      context: data,
      hasBackdrop: true,
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getActionUuidString(actionString: string) {
    if (actionString) {
      const parts = actionString.split(":");
      if (parts.length === 2) {
        return parts[1].trim(); // Devuelve el UUID sin espacios adicionales
      }
    }
  }
}
