import { Injectable } from '@angular/core';
import { Base64FileDto } from '../api/models/base-64-file-dto';
import { ComponentsService } from './components.service';

@Injectable({
  providedIn: 'root'
})
export class DownloaderService {

  constructor(private readonly componentsService: ComponentsService) { }

  downloadFromBase64(base64: string, filename: string, mimeType: string) {
    // Convertir base64 a un arreglo buffer
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Crear un blob con los datos
    const blob = new Blob([byteArray], { type: mimeType });

    // Crear un link para descargar el blob
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    // Limpieza
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  importCsvFile(event): Promise<string> {
    return new Promise((resolve, reject) => {
      const file: File = event.target.files[0];
      if (file) {
        const reader: FileReader = new FileReader();
        const fileName: string = file.name;
        const fileExtension: string = fileName.split('.').pop().toLowerCase();

        if (fileExtension !== 'csv') {
          reject('Solo se permiten archivos CSV');
          return;
        }

        reader.onload = (e: any) => {
          const csvContent: string = e.target.result;
          resolve(csvContent);
        };
        reader.readAsText(file);
      } else {
        reject('No se seleccionó ningún archivo');
      }
    });
  }
  
  parseCsv(csvContent: string): any[] {
    const lines = csvContent.split('\n');
    const headers = lines[0].split(',').map(header => header.trim());
    const result = [];

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].trim() === '') continue; // Omitir líneas vacías
      const currentline = lines[i].split(',');
      if (currentline.length !== headers.length) continue; // Omitir líneas con columnas incompletas
      const obj = {};

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]?.trim() || '';
      }

      result.push(obj);
    }

    return result;
  }

  validateCsvData(data: any[], requiredFields: string[]): { valid: boolean, errors: string[] } {
    const errors = [];

    data.forEach((row, index) => {
      requiredFields.forEach(field => {
        if (!row[field]) {
          errors.push(`Registro ${index + 1}: falta el campo ${field}`);
        }
      });
    });

    return { valid: errors.length === 0, errors };
  }
}
