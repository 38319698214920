import { Component, Input, OnInit } from '@angular/core';
import { ProfilesGroupedByPersonalityVarDto, ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-accumulated-bars-graph',
  templateUrl: './accumulated-bars-graph.component.html',
  styleUrls: ['./accumulated-bars-graph.component.scss']
})
export class AccumulatedBarsGraphComponent implements OnInit{
  @Input() colorScheme;
  @Input() graphData: Array<ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto>;
  @Input() isVulnerability: boolean = false;
  @Input() fullView: boolean = false;
  values = [];
  multi: any[];
  view: any[] = [300, 158];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  constructor() {
  }

  ngOnInit(): void {
    if (this.fullView) this.view = [1000, 600]
    if (this.graphData) {
      this.formatValues()
    }
  }

  formatValues() {
    if ( this.isVulnerability) {
      this.graphData.map((itm: ProfilesGroupedByVulnerabilityLevelDto) => {
        this.values.push({
          "name": itm.label,
          "series": [
            {
              "name": "Vulnerabilidad alta",
              "value": itm.highVulnerabilityTotalProfileCount
            },
            {
              "name": "Vulnerabilidad media",
              "value": itm.mediumVulnerabilityTotalProfileCount
            },
            {
              "name": "Vulnerabilidad baja",
              "value": itm.lowVulnerabilityTotalProfileCount
            },
          ]
        })
      })
    } else {
      this.graphData.map((itm: ProfilesGroupedByPersonalityVarDto) => {
        this.values.push({
          "name": itm.label,
          "series": [
            {
              "name": "Vulnerabilidad alta",
              "value": itm.highLevelTotalProfileCount
            },
            {
              "name": "Vulnerabilidad media-alta",
              "value": itm.midhighLevelTotalProfileCount
            },
            {
              "name": "Vulnerabilidad media-baja",
              "value": itm.midlowLevelTotalProfileCount
            },
            {
              "name": "Vulnerabilidad baja",
              "value": itm.lowLevelTotalProfileCount
            },
          ]
        })
      })
    }
  }
  onSelect(event) {
  }

  formatInteger(value: any): string {
    return Math.round(value).toString(); // Convertimos el valor a entero
  }

  formatLabel(value: string): string {
    const maxLength = 5; // Número máximo de caracteres antes de recortar
    return value.length > maxLength ? value.slice(0, maxLength) + '...' : value;
  }

  removeDecimals(value: number): string {
    return Number.isInteger(value) ? value.toString() : ''; // Si el valor es entero, lo muestra
  }
}
