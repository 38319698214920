/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BisBasLevelsDto } from '../models/bis-bas-levels-dto';
import { CampaignProfileModelDto } from '../models/campaign-profile-model-dto';
import { CampaignTimelineDto } from '../models/campaign-timeline-dto';
import { EventsResultsGraphDto } from '../models/events-results-graph-dto';
import { ProfileTimelineDto } from '../models/profile-timeline-dto';
import { RecipientsStatusDto } from '../models/recipients-status-dto';
import { ShownInfoFilterDto } from '../models/shown-info-filter-dto';

@Injectable({
  providedIn: 'root',
})
export class CampaignProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation campaignResultsControllerGetEventsResultsGraphByCampaignUuid
   */
  static readonly CampaignResultsControllerGetEventsResultsGraphByCampaignUuidPath = '/campaign-profile/{campaignUuid}/events-results-graph';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetEventsResultsGraphByCampaignUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetEventsResultsGraphByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EventsResultsGraphDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetEventsResultsGraphByCampaignUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventsResultsGraphDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetEventsResultsGraphByCampaignUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetEventsResultsGraphByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
  }
): Observable<Array<EventsResultsGraphDto>> {

    return this.campaignResultsControllerGetEventsResultsGraphByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EventsResultsGraphDto>>) => r.body as Array<EventsResultsGraphDto>)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid
   */
  static readonly CampaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuidPath = '/campaign-profile/{campaignUuid}/events-results-graph/profile-types';

  /**
   * Get graphs data by profile bis / bas level.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
    body: BisBasLevelsDto
  }
): Observable<StrictHttpResponse<Array<EventsResultsGraphDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventsResultsGraphDto>>;
      })
    );
  }

  /**
   * Get graphs data by profile bis / bas level.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
    body: BisBasLevelsDto
  }
): Observable<Array<EventsResultsGraphDto>> {

    return this.campaignResultsControllerGetEventsResultsGraphByProfileTypeByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EventsResultsGraphDto>>) => r.body as Array<EventsResultsGraphDto>)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid
   */
  static readonly CampaignResultsControllerGetRecipientsResultsStatusByCampaignUuidPath = '/campaign-profile/{campaignUuid}/recipients-results-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;

    /**
     * Profile email
     */
    email?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RecipientsStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetRecipientsResultsStatusByCampaignUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RecipientsStatusDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;

    /**
     * Profile email
     */
    email?: string;
    context?: HttpContext
  }
): Observable<Array<RecipientsStatusDto>> {

    return this.campaignResultsControllerGetRecipientsResultsStatusByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RecipientsStatusDto>>) => r.body as Array<RecipientsStatusDto>)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetTimelineByCampaignUuid
   */
  static readonly CampaignResultsControllerGetTimelineByCampaignUuidPath = '/campaign-profile/{campaignUuid}/profile-types/timeline';

  /**
   * Get timeline by profile type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetTimelineByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetTimelineByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
    body: BisBasLevelsDto
  }
): Observable<StrictHttpResponse<CampaignTimelineDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetTimelineByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignTimelineDto>;
      })
    );
  }

  /**
   * Get timeline by profile type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetTimelineByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetTimelineByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    campaignUuid: string;
    context?: HttpContext
    body: BisBasLevelsDto
  }
): Observable<CampaignTimelineDto> {

    return this.campaignResultsControllerGetTimelineByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignTimelineDto>) => r.body as CampaignTimelineDto)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid
   */
  static readonly CampaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuidPath = '/campaign-profile/{campaignUuid}/campaign-profile/{uuid}/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid$Response(params: {
    workspace?: string;

    /**
     * campaign uuid to filter by
     */
    campaignUuid: string;

    /**
     * profile uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ProfileTimelineDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileTimelineDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid(params: {
    workspace?: string;

    /**
     * campaign uuid to filter by
     */
    campaignUuid: string;

    /**
     * profile uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<ProfileTimelineDto> {

    return this.campaignResultsControllerGetTimelineByCampaignAndCampaignProfileUuid$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileTimelineDto>) => r.body as ProfileTimelineDto)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetProfilesTimelineByCampaignUuid
   */
  static readonly CampaignResultsControllerGetProfilesTimelineByCampaignUuidPath = '/campaign-profile/{campaignUuid}/campaign-profiles/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetProfilesTimelineByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetProfilesTimelineByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * campaign uuid to filter by
     */
    campaignUuid: string;

    /**
     * Profile email
     */
    email?: string;
    context?: HttpContext
    body: ShownInfoFilterDto
  }
): Observable<StrictHttpResponse<Array<ProfileTimelineDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetProfilesTimelineByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('campaignUuid', params.campaignUuid, {});
      rb.query('email', params.email, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileTimelineDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetProfilesTimelineByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerGetProfilesTimelineByCampaignUuid(params: {
    workspace?: string;

    /**
     * campaign uuid to filter by
     */
    campaignUuid: string;

    /**
     * Profile email
     */
    email?: string;
    context?: HttpContext
    body: ShownInfoFilterDto
  }
): Observable<Array<ProfileTimelineDto>> {

    return this.campaignResultsControllerGetProfilesTimelineByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileTimelineDto>>) => r.body as Array<ProfileTimelineDto>)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetCampaignResults
   */
  static readonly CampaignResultsControllerGetCampaignResultsPath = '/campaign-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetCampaignResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetCampaignResults$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CampaignProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetCampaignResultsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetCampaignResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetCampaignResults(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<CampaignProfileModelDto>> {

    return this.campaignResultsControllerGetCampaignResults$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignProfileModelDto>>) => r.body as Array<CampaignProfileModelDto>)
    );
  }

  /**
   * Path part for operation campaignResultsControllerCreateCampaignResult
   */
  static readonly CampaignResultsControllerCreateCampaignResultPath = '/campaign-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerCreateCampaignResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerCreateCampaignResult$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignProfileModelDto
  }
): Observable<StrictHttpResponse<CampaignProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerCreateCampaignResultPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerCreateCampaignResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerCreateCampaignResult(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignProfileModelDto
  }
): Observable<CampaignProfileModelDto> {

    return this.campaignResultsControllerCreateCampaignResult$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignProfileModelDto>) => r.body as CampaignProfileModelDto)
    );
  }

  /**
   * Path part for operation campaignResultsControllerGetCampaignResultByUuid
   */
  static readonly CampaignResultsControllerGetCampaignResultByUuidPath = '/campaign-profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerGetCampaignResultByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetCampaignResultByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerGetCampaignResultByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerGetCampaignResultByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerGetCampaignResultByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<CampaignProfileModelDto> {

    return this.campaignResultsControllerGetCampaignResultByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignProfileModelDto>) => r.body as CampaignProfileModelDto)
    );
  }

  /**
   * Path part for operation campaignResultsControllerUpdateCampaignResult
   */
  static readonly CampaignResultsControllerUpdateCampaignResultPath = '/campaign-profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerUpdateCampaignResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerUpdateCampaignResult$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignProfileModelDto
  }
): Observable<StrictHttpResponse<CampaignProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerUpdateCampaignResultPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerUpdateCampaignResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignResultsControllerUpdateCampaignResult(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignProfileModelDto
  }
): Observable<CampaignProfileModelDto> {

    return this.campaignResultsControllerUpdateCampaignResult$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignProfileModelDto>) => r.body as CampaignProfileModelDto)
    );
  }

  /**
   * Path part for operation campaignResultsControllerDeleteCampaignResult
   */
  static readonly CampaignResultsControllerDeleteCampaignResultPath = '/campaign-profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignResultsControllerDeleteCampaignResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerDeleteCampaignResult$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignProfileService.CampaignResultsControllerDeleteCampaignResultPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignResultsControllerDeleteCampaignResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignResultsControllerDeleteCampaignResult(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.campaignResultsControllerDeleteCampaignResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
