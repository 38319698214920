import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';import { KeycloakService } from 'keycloak-angular';
import { switchMap, catchError } from 'rxjs/operators';
import { CoreProvider, WORKSPACE } from '../services/core.provider';
import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  readonly excludedUrls = [
    '/workspace/findByName/',
    '/workspace/findByEmail/',
  ]
  constructor(private keycloakService: KeycloakService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requiereToken = !this.excludedUrls.some(url => request.url.includes(url));
    if (requiereToken) {
      return from(this.keycloakService.getToken()).pipe(
        switchMap(token => {
          if (token) {
            request = request.clone({
              setHeaders: {
                workspace : WORKSPACE,
                Authorization: `Bearer ${token}`
              }
            });
          } else{
            console.error('No token');
          }
          return next.handle(request);
        }),
        catchError(error => {
          // Adecuado manejo del error
          if (error.status === 401 || error.status === 403) {
            this.keycloakService.login();
          } else if (error.status === 404 ) {
            // this.router.navigateByUrl("/public/select-workspace")
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
