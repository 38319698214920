import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CoreProvider } from "../../../../services/services/core.provider";
import {
  KeycloakUserDto,
  ProfileModelDto,
} from "../../../../services/api/models";
import { PaginationService } from "../../../../services/services/pagination.service";
import { ComponentsService } from "../../../../services/services/components.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-basic-modal",
  templateUrl: "./basic-modal.component.html",
  styleUrls: ["./basic-modal.component.scss"],
})
export class BasicModalComponent {
  text: string;
  title: string;
  constructor(private readonly core: CoreProvider, private readonly router: Router) {
    if (this.core.isUserAdmin()) {
      this.title = '¡Alerta crítica!';
      this.text =
        "El nivel de vulnerabilidad de tu organización ha superado el umbral recomendado.";
    } else {
      this.title = '¡Atención!';
      this.text =
        "Tu nivel de vulnerabilidad ha superado el umbral establecido.";
    }
  }

  closeInitialAlert() {
    this.core.closeInitialAlert();
  }

  goToDashboard() {
    this.router.navigateByUrl('/account/dashboard');
    this.core.closeInitialAlert();
  }
}
