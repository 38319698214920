/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TypeModelDto } from '../models/type-model-dto';

@Injectable({
  providedIn: 'root',
})
export class TypeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation typeControllerGetTypes
   */
  static readonly TypeControllerGetTypesPath = '/type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `typeControllerGetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerGetTypes$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TypeModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TypeService.TypeControllerGetTypesPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TypeModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `typeControllerGetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerGetTypes(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<TypeModelDto>> {

    return this.typeControllerGetTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TypeModelDto>>) => r.body as Array<TypeModelDto>)
    );
  }

  /**
   * Path part for operation typeControllerCreateType
   */
  static readonly TypeControllerCreateTypePath = '/type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `typeControllerCreateType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  typeControllerCreateType$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: TypeModelDto
  }
): Observable<StrictHttpResponse<TypeModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, TypeService.TypeControllerCreateTypePath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TypeModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `typeControllerCreateType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  typeControllerCreateType(params: {
    workspace?: string;
    context?: HttpContext
    body: TypeModelDto
  }
): Observable<TypeModelDto> {

    return this.typeControllerCreateType$Response(params).pipe(
      map((r: StrictHttpResponse<TypeModelDto>) => r.body as TypeModelDto)
    );
  }

  /**
   * Path part for operation typeControllerGetTypeByUuid
   */
  static readonly TypeControllerGetTypeByUuidPath = '/type/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `typeControllerGetTypeByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerGetTypeByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TypeModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, TypeService.TypeControllerGetTypeByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TypeModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `typeControllerGetTypeByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerGetTypeByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<TypeModelDto> {

    return this.typeControllerGetTypeByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<TypeModelDto>) => r.body as TypeModelDto)
    );
  }

  /**
   * Path part for operation typeControllerUpdateType
   */
  static readonly TypeControllerUpdateTypePath = '/type/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `typeControllerUpdateType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  typeControllerUpdateType$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: TypeModelDto
  }
): Observable<StrictHttpResponse<TypeModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, TypeService.TypeControllerUpdateTypePath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TypeModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `typeControllerUpdateType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  typeControllerUpdateType(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: TypeModelDto
  }
): Observable<TypeModelDto> {

    return this.typeControllerUpdateType$Response(params).pipe(
      map((r: StrictHttpResponse<TypeModelDto>) => r.body as TypeModelDto)
    );
  }

  /**
   * Path part for operation typeControllerDeleteType
   */
  static readonly TypeControllerDeleteTypePath = '/type/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `typeControllerDeleteType()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerDeleteType$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TypeService.TypeControllerDeleteTypePath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `typeControllerDeleteType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  typeControllerDeleteType(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.typeControllerDeleteType$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
