import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ComponentsService } from '../../../services/services/components.service';
import { ModalConfirmationTextComponent } from '../../../main-module/account-module/eve-module/components/modal-confirmation-text/modal-confirmation-text.component';
import { environment } from '../../../../environments/environment';
import { CoreProvider } from '../../../services/services/core.provider';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="socials">
      <img class="footer-class h-100" src="assets/imgs/incibe_white_band.svg">
      <ng-container *ngIf="(isDemoWorkspace && !isUserAdmin)">
        <div (click)="openLegalTerms()" class="legal-terms-demo mt-2">
          <p class="m-auto ">
            {{ 'Aviso legal' }}
          </p>
        </div>  
      </ng-container>
      <ng-container *ngIf="(!isDemoWorkspace && !isUserAdmin)">
        <div class="w-100 d-flex justify-content-around">
          <div (click)="openLegalTerms('legalAdvice')" class="legal-terms my-2">
            <p class="m-auto ">
              {{ 'Aviso legal' }}
            </p>
          </div>
          <div (click)="openLegalTerms('privacyPolicy')" class="legal-terms my-2">
            <p class="m-auto ">
              {{ 'Política de Privacidad' }}
            </p>
          </div>  
</div>
      </ng-container>
    </div>

    <ng-template #modalText let-ref="dialogRef" let-data let-itm="itm">
  <nb-card class="big-modal h-100">
    <ngx-modal-legal-advice class="h-100" [textToShow]="data.type" (closeModal)="ref.close(); isModalOpen = false"></ngx-modal-legal-advice>
  </nb-card>
</ng-template>
  `,
})
export class FooterComponent {
  isDemoWorkspace: boolean = false;
  @ViewChild("modalText") modalText: TemplateRef<ModalConfirmationTextComponent>;
  isUserAdmin: boolean = false;
  isModalOpen: boolean = false;
  constructor(private readonly core: CoreProvider, private readonly componentsService: ComponentsService) {
    this.isDemoWorkspace = environment.isDemo;
    this.isUserAdmin = this.core.isUserAdmin();

  }
  openLegalTerms(typeModal: string) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.componentsService.openModal(
        this.modalText,
        {type: typeModal},
        true,
        "legalModal"
      );
    }
  }
}
