import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { ProfileModelDto } from "../api/models";
import { CoreProvider } from "../services/core.provider";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { InitService } from "../services/init.service";

@Injectable({
  providedIn: "root",
})
export class DemoGuard implements CanActivate {
  userDataSubscription: Subscription;
  userData: ProfileModelDto;

  private readonly demoRoutes: string[] = ["/resource", "/public-results"];

  constructor(private readonly core: CoreProvider) {}

  // This guard should only apply to demo workspaces
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree> {
    if (!environment.isDemo) return true;

    const url = state.url;
    const isPublicRoute = this.demoRoutes.some((publicRoute) => url.includes(publicRoute));


    // Si es una ruta pública y el entorno es demo, permitir acceso
    if (isPublicRoute && environment.isDemo) {
      return true;
    }

    // Si no es ruta pública y el entorno es demo y el usuario no es admin, bloquear acceso
    if (!isPublicRoute && environment.isDemo && !this.core.isUserAdmin()) {
      // AR Disabled: await this.core.initService.checkAction();

      // this.router.navigate(['/resource']); // Redirigir a una ruta pública
      return false;
    }

    // Si el entorno no es demo o el usuario es admin, permitir acceso
    if (!isPublicRoute || (environment.isDemo && this.core.isUserAdmin())) {
      return true;
    }

    // Si ninguna condición se cumple, bloquear acceso
    return this.core.router.createUrlTree(['/']);
  }
}
