import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ProfilesGroupedByPersonalityVarDto, ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-dashboard-pie-graph',
  templateUrl: './dashboard-pie-graph.component.html',
  styleUrls: ['./dashboard-pie-graph.component.scss']
})
export class DashboardPieGraphComponent implements OnInit {  
  @Input() colorScheme;
  @Input() isCentral: boolean = false;
  @Input() isVulnerability: boolean = false;

  @Input() graphData: ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto;
  view: any[] = [300, 158];
  results: any;
  constructor() { 
  
  }
  
  ngOnInit() {
    if (this.isCentral) {
      this.view = null;
    }

    if ( this.graphData ) {
      this.checkValues();
  
    }
  }

  ngOnDestroy(): void {
  }

  checkValues() {
    this.results = [];
  
    if (this.isVulnerability) {
      this.results.push({
        name: 'Vulnerabilidad Alta',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.highVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'high-vulnerability' }
      });
  
      this.results.push({
        name: 'Vulnerabilidad Media',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.mediumVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'medium-vulnerability' }
      });
  
      this.results.push({
        name: 'Vulnerabilidad Baja',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.lowVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'low-vulnerability' }
      });
    } else {
      this.results.push({
        name: 'Alto Nivel',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.highLevelTotalProfileCount) || 0,
        extra: { code: 'high-level' }
      });
  
      this.results.push({
        name: 'Nivel Medio-Alto',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.midhighLevelTotalProfileCount) || 0,
        extra: { code: 'midhigh-level' }
      });
  
      this.results.push({
        name: 'Nivel Medio-Bajo',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.midlowLevelTotalProfileCount) || 0,
        extra: { code: 'midlow-level' }
      });
  
      this.results.push({
        name: 'Bajo Nivel',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.lowLevelTotalProfileCount) || 0,
        extra: { code: 'low-level' }
      });
    }
  }
} 

