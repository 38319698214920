/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnvConfigModelDto } from '../models/env-config-model-dto';

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation envConfigControllerGetEnvconfig
   */
  static readonly EnvConfigControllerGetEnvconfigPath = '/envconfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envConfigControllerGetEnvconfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerGetEnvconfig$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EnvConfigModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EnvConfigService.EnvConfigControllerGetEnvconfigPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnvConfigModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envConfigControllerGetEnvconfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerGetEnvconfig(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<EnvConfigModelDto>> {

    return this.envConfigControllerGetEnvconfig$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnvConfigModelDto>>) => r.body as Array<EnvConfigModelDto>)
    );
  }

  /**
   * Path part for operation envConfigControllerCreateEnvconfig
   */
  static readonly EnvConfigControllerCreateEnvconfigPath = '/envconfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envConfigControllerCreateEnvconfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envConfigControllerCreateEnvconfig$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: EnvConfigModelDto
  }
): Observable<StrictHttpResponse<EnvConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, EnvConfigService.EnvConfigControllerCreateEnvconfigPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnvConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envConfigControllerCreateEnvconfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envConfigControllerCreateEnvconfig(params: {
    workspace?: string;
    context?: HttpContext
    body: EnvConfigModelDto
  }
): Observable<EnvConfigModelDto> {

    return this.envConfigControllerCreateEnvconfig$Response(params).pipe(
      map((r: StrictHttpResponse<EnvConfigModelDto>) => r.body as EnvConfigModelDto)
    );
  }

  /**
   * Path part for operation envConfigControllerGetEnvconfigByUuid
   */
  static readonly EnvConfigControllerGetEnvconfigByUuidPath = '/envconfig/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envConfigControllerGetEnvconfigByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerGetEnvconfigByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EnvConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, EnvConfigService.EnvConfigControllerGetEnvconfigByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnvConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envConfigControllerGetEnvconfigByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerGetEnvconfigByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<EnvConfigModelDto> {

    return this.envConfigControllerGetEnvconfigByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<EnvConfigModelDto>) => r.body as EnvConfigModelDto)
    );
  }

  /**
   * Path part for operation envConfigControllerUpdateEnvconfig
   */
  static readonly EnvConfigControllerUpdateEnvconfigPath = '/envconfig/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envConfigControllerUpdateEnvconfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envConfigControllerUpdateEnvconfig$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: EnvConfigModelDto
  }
): Observable<StrictHttpResponse<EnvConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, EnvConfigService.EnvConfigControllerUpdateEnvconfigPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnvConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envConfigControllerUpdateEnvconfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envConfigControllerUpdateEnvconfig(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: EnvConfigModelDto
  }
): Observable<EnvConfigModelDto> {

    return this.envConfigControllerUpdateEnvconfig$Response(params).pipe(
      map((r: StrictHttpResponse<EnvConfigModelDto>) => r.body as EnvConfigModelDto)
    );
  }

  /**
   * Path part for operation envConfigControllerDeleteEnvconfig
   */
  static readonly EnvConfigControllerDeleteEnvconfigPath = '/envconfig/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envConfigControllerDeleteEnvconfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerDeleteEnvconfig$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EnvConfigService.EnvConfigControllerDeleteEnvconfigPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envConfigControllerDeleteEnvconfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envConfigControllerDeleteEnvconfig(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.envConfigControllerDeleteEnvconfig$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
