<section class="d-flex flex-column mt-2">
  <nb-accordion>
    <nb-accordion-item *ngFor="let item of accorList">
      <nb-accordion-item-header>{{item.title}}</nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="w-100 text-wrap text-break">{{ item.content }}</div>
        <div class="form-control-group accept-group mt-3">
          <nb-checkbox name="terms">{{item.checkboxText}}</nb-checkbox>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</section>
