import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { MENU_ITEMS } from '../../main-module/account-module/account-menu';
import { CoreProvider } from './core.provider';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private core: CoreProvider) {}

  async updateMenuItems(): Promise<void> {
    const userRoles = this.core.roles;
    const filteredMenuItems = this.filterMenuItems(userRoles);
    // Actualizar la constante MENU_ITEMS
    MENU_ITEMS.splice(0, MENU_ITEMS.length, ...filteredMenuItems);
  }

  private filterMenuItems(userRoles: string[]): NbMenuItem[] {
    const filterItems = (items: NbMenuItem[]): NbMenuItem[] => {
      return items
        .filter(item => this.hasAccess(item, userRoles))
        .map(item => {
          const filteredItem = { ...item }; // Crear una copia del item
          if (item.children) {
            filteredItem.children = filterItems(item.children);
          }
          return filteredItem;
        });
    };

    return filterItems(MENU_ITEMS);
  }

  private hasAccess(item: NbMenuItem, userRoles: string[]): boolean {
    if (!item.data || !item.data.roles) {
      return true; // Si no se especifican roles, todos los usuarios tienen acceso
    }
    return item.data.roles.some(role => userRoles.includes(role));
  }
}
