import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private footerVisibleSubject = new BehaviorSubject<boolean>(true);
  footerVisible$ = this.footerVisibleSubject.asObservable();

  constructor() { }

  toggleFooterVisibility(visible: boolean): void {
    this.footerVisibleSubject.next(visible);
  }
}
