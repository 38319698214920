


<div class="d-flex justify-center align-items-center w-100 h-100">
  <ngx-charts-bar-vertical-stacked
  [view]="view"
  [scheme]="colorScheme"
  [results]="values"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [xAxisTickFormatting]="formatLabel"
  [yAxisTickFormatting]="formatInteger"
  [animations]="animations"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical-stacked>
</div>