import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NbSidebarComponent, NbSidebarService } from '@nebular/theme';
import { MENU_ITEMS } from '../../../main-module/account-module/account-menu';
import { FooterService } from '../../../services/services/footer.service';
import { SidebarService } from '../../../services/services/sidebar.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
  <nb-layout windowMode>
  <nb-layout-header class="header-border fixed">
    <ngx-header></ngx-header>
  </nb-layout-header>

  <!-- <nb-sidebar tag="menu-sidebar" state="compacted">
  <nb-menu [items]="menuItems">
    <ng-template let-item ngFor [ngForOf]="menuItems">
      <a nbMenuItem [routerLink]="item.link">
        <nb-icon *ngIf="item.icon" [icon]="item.icon" pack="custom"></nb-icon>
        <span>{{ item.title }}</span>
      </a>
    </ng-template>
  </nb-menu>
</nb-sidebar> -->

<nb-sidebar [ngClass]="{'disp-none': sidebarCustomService.isTransition,'expanded-sidebar': sidebarExpanded$|async}" class="menu-sidebar d-flex" tag="menu-sidebar"
[collapsedBreakpoints]="['xs', 'is','sm', 'md', 'lg']"
#sidebar responsive>
<button *ngIf="sidebar && sidebar?.fixedValue && sidebar?.state === 'expanded'" nbButton class="button-sidebar-collapse" size="medium" (click)="this.sidebarCustomService.toggleSidebarVisibility(!sidebarExpanded$.value, false)" hero nbButton status="info"><nb-icon
  [icon]="sidebar && sidebar?.state === 'expanded' ? 'chevron-left' : 'chevron-right' "
  pack="fa"></nb-icon></button>
  <ng-content select="nb-menu"></ng-content>
  <div *ngIf="sidebarExpanded$|async" class="d-flex pt-3 justify-content-center">
    <small class="text-center">{{ '© 2024 Copyright: EVE Intelligence versión 1.4.1-beta' }}</small>
  </div>
</nb-sidebar>

  <nb-layout-column >
  <button #buttonSidebar *ngIf="(!sidebar?.fixedValue || (sidebar?.fixedValue && sidebar?.state === 'collapsed')) && (showButton || !sidebarCustomService.isTransition)" nbButton [ngClass]="sidebar?.state === 'collapsed' ? 'button-layout-expand' : 'button-layout-collapse'" size="medium" (click)="this.sidebarCustomService.toggleSidebarVisibility(!sidebarExpanded$.value, false)" hero nbButton status="info"><nb-icon
  [icon]="sidebar && sidebar?.state === 'expanded' ? 'chevron-left' : 'chevron-right' "
  pack="fa"></nb-icon></button>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

  <nb-layout-footer *ngIf="footerVisible" class="footer-transition" fixed>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>

  `,
})
export class OneColumnLayoutComponent implements AfterViewInit, OnDestroy {
  menuItems= MENU_ITEMS;
  @ViewChild('buttonSidebar', { static: true }) buttonSidebar: ElementRef<HTMLElement>;
  // TODO: Improved the resizing sidebar logic
  @ViewChild('sidebar', { static: true }) sidebar: NbSidebarComponent;

  sidebarExpanded$ = this.sidebarCustomService.sidebarVisible$;
  isSidebarLocked: boolean = false;;
  footerVisible: boolean = true;
  showButton: boolean = true;

  subscriptions = [];

  constructor(
    public sidebarCustomService: SidebarService,
    private sidebarService: NbSidebarService,
    public footerService: FooterService
  ) {
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      const subscription = this.subscriptions[i];
      subscription?.unsubscribe();
      this.subscriptions.splice(this.subscriptions.indexOf(subscription), 1);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.sidebarCustomService.sidebarVisible$.subscribe(async visible => {
      await this.toggleSidebar(visible);
      this.showButton = visible;
    }));

    this.subscriptions.push(this.footerService.footerVisible$.subscribe(visible => {
      this.footerVisible = visible;
    }));
  }

  private async toggleSidebar(open: boolean = true) {

    if (open) {
      this.sidebarService.expand("menu-sidebar");
    } else {
      this.sidebarService.collapse("menu-sidebar");
    }
  }
}
