import { Injectable } from '@angular/core';
import { ExerciseModelDto } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  progress: number = 0;
  roundedProgress: number = 0;

  totalQuestions: number = 0;
  stepSize: number = 0;

  showProgressBar: boolean = false;
  constructor() {}

  /**
   * Calcula el tamaño del paso basado en el número total de preguntas.
   * @param exercises - Lista de ejercicios que contienen las preguntas.
   */
  calculateStepSize(exercises: ExerciseModelDto[]): void {
    this.showProgressBar = true;
  
    this.totalQuestions = exercises.reduce((acc, exercise) => {
      // Filtrar las preguntas que tienen al menos una questionAnswerOpts
      const questionsWithAnswerOpts = exercise.questions.filter(question => question?.questionAnswerOpts?.length > 0);
      
      // Log cada pregunta con al menos una opción de respuesta
      questionsWithAnswerOpts.forEach(question => {
      });
      
      // Sumar el número de preguntas válidas al acumulador
      return acc + questionsWithAnswerOpts.length;
    }, 0);
    // this.totalQuestions = this.totalQuestions -1;
    this.stepSize = this.totalQuestions > 0 ? 100 / this.totalQuestions : 0;
  }

  /**
   * Incrementa el progreso de la barra sin exceder el 100%.
   */
  increaseProgress(): void {
    if (this.progress + this.stepSize <= 100) {
      this.progress += this.stepSize;
      this.roundedProgress = Math.round(this.progress);
    } else {
      this.progress = 100; // Asegura que no supere el 100%
    }
  }

  /**
   * Resetea el progreso a 0 (opcional, según tus necesidades).
   */
  resetProgress(): void {
    this.progress = 0;
    this.roundedProgress = 0;
  }
}
