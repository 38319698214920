import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  NbMenuItem,
  NbMenuService,
  NbThemeService,
} from "@nebular/theme";
import { Subject, Subscription, filter, map } from "rxjs";
import { CoreProvider } from "../../../services/services/core.provider";
import {
  ProfileModelDto,
  ReducedWorkspaceDto,
} from "../../../services/api/models";
import { ComponentsService } from "../../../services/services/components.service";
import { environment } from "../../../../environments/environment";
import { ModalConfirmationTextComponent } from "../../../main-module/account-module/eve-module/components/modal-confirmation-text/modal-confirmation-text.component";
import { HeaderService } from "../../../services/services/header.service";

interface ExtendedNbMenuItem extends NbMenuItem {
  action?: () => void; // Añade la propiedad de acción opcional
}

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("modalText")
  modalText: TemplateRef<ModalConfirmationTextComponent>;
  
  private destroy$: Subject<void> = new Subject<void>();
  userDataSubscription: Subscription;
  userData: ProfileModelDto;
  workSpaceSubscription: Subscription;

  userPictureOnly: boolean = false;
  isModalOpen: boolean = false;
  user: any;

  workspace: ReducedWorkspaceDto;
  isUserAdmin: boolean = false;
  userMenu = [
    // {
    //   id: 1,
    //   title: "Profile",
    //   action: () => this.router.navigateByUrl("/account/profile"),
    // },
    { id: 2, title: "Log Out", action: () => this.core.logOut() },
  ];

  isDemoWorkspace: boolean = true;
  constructor(
    private readonly core: CoreProvider,
    private menuService: NbMenuService,
    private readonly componentsService: ComponentsService,
    private themeService: NbThemeService,
    public readonly headerService: HeaderService
  ) {
    this.isDemoWorkspace = environment.isDemo;
    // this.isDemoWorkspace = this.core.workspaceInfo.demo;
  }
  get progress(): number {
    return this.headerService.roundedProgress;
  }
  async ngOnInit() {
    this.userDataSubscription = this.core.getUserData().subscribe({
      next: (res) => {
        this.userData = res;
        if (!this.userData.avatar) {
          // this.profile.avatar = "assets/imgs/default-avatar.svg";
        } else {
          // this.profile.avatar = `data:image/png;base64,${this.profile.avatar}`;
        }
      },
      error: (err) => {
        this.componentsService.showToast(
          "danger",
          "Hemos tenido un problema al obtener tus datos de usuario. Recarga la pestaña por favor.",
          "Uf..."
        );
      },
    });

    this.isUserAdmin = this.core.isUserAdmin();

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "user-options"),
        map(({ item }) => item as ExtendedNbMenuItem)
      )
      .subscribe((item) => {
        if (item.action) {
          item.action();
        } else {
          // TODO: Implementar redirección o acciones en los menú, cuando estén definidos
        }
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  openLegalTerms() {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.componentsService.openModal(
        this.modalText,
        null,
        true,
        "legalModal"
      );
    }
  }

}
