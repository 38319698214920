<div *ngIf="results" class="d-flex justify-center align-items-center w-100 h-100">
  <ngx-charts-pie-chart
        [scheme]="colorScheme"
        [results]="results"
        [view]="view"
        [labels]="true"
        [explodeSlices]="false"
        >
      </ngx-charts-pie-chart>
</div>

<ng-template #textLegend></ng-template>