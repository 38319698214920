import { Component, Input, OnInit } from '@angular/core';
import { ProfilesGroupedByPersonalityVarDto, ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input() colorScheme;
  @Input() graphData: Array<ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto>;

  multi: any[] = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        },
        {
          "name": "2012",
          "value": 8940000
        },
        {
          "name": "2013",
          "value": 8940000
        }
      ]
    },
    {
      "name": "France",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        },
        {
          "name": "2012",
          "value": 7300000
        }, {
          "name": "2013",
          "value": 7300000
        },
      ]
    },
    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        },
        {
          "name": "2012",
          "value": 7870000
        },
        {
          "name": "2013",
          "value": 7870000
        },
      ]
    }
  ];
  view: any[] = [300, 158];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;

  constructor() {
  }

  ngOnInit() {
    if (this.graphData) {
      this.checkValues();
    }
  }


  checkValues() {
    this.multi = [];
  
    const highLevelSeries = { name: 'Alto Nivel', series: [] };
    const midhighLevelSeries = { name: 'Nivel Medio-Alto', series: [] };
    const midlowLevelSeries = { name: 'Nivel Medio-Bajo', series: [] };
    const lowLevelSeries = { name: 'Bajo Nivel', series: [] };

    // Recorre los datos y asigna valores a cada serie
    this.graphData.map((itm: ProfilesGroupedByPersonalityVarDto) => {
      highLevelSeries.series.push({
        name: this.getMonthName(itm.month),
        value: itm.highLevelTotalProfileCount
      });
  
      midhighLevelSeries.series.push({
        name: this.getMonthName(itm.month),
        value: itm.midhighLevelTotalProfileCount
      });
  
      midlowLevelSeries.series.push({
        name: this.getMonthName(itm.month),
        value: itm.midlowLevelTotalProfileCount
      });
  
      lowLevelSeries.series.push({
        name: this.getMonthName(itm.month),
        value: itm.lowLevelTotalProfileCount
      });
    });
  
    // Añade las 4 series al array `multi`
    this.multi.push(highLevelSeries, midhighLevelSeries, midlowLevelSeries, lowLevelSeries);
  }
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getMonthName(monthNumber: number): string {
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const monthIndex = monthNumber - 1;
    return months[monthIndex] || 'Mes inválido';
  }

  formatInteger(value: any): string {
    return Math.round(value).toString(); // Convertimos el valor a entero
  }
}
