import { NgModule } from '@angular/core';
import { NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbChatCustomMessageService, NbChatModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbSpinnerModule, NbTabsetComponent, NbTabsetModule, NbTooltipModule, NbUserModule } from '@nebular/theme';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';
import { TabTableConfirmComponent } from '../../components/tab-table-confirm/tab-table-confirm.component';
import { AcceptConditionsComponent } from './accept-conditions/accept-conditions.component';
import { GeneralComponentsModule } from '../../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardPieGraphComponent } from './dashboard-pie-graph/dashboard-pie-graph.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AccumulatedBarsGraphComponent } from './accumulated-bars-graph/accumulated-bars-graph.component';
import { DashboardAreaChartComponent } from './dashboard-area-chart/dashboard-area-chart.component';
import { GraphsTabsetComponent } from './graphs-tabset/graphs-tabset.component';
import { LineChartComponent } from './line-chart/line-chart.component';

const COMPONENTS = [
  AcceptConditionsComponent,
  DashboardPieGraphComponent,
  AccumulatedBarsGraphComponent,
  DashboardAreaChartComponent,
  GraphsTabsetComponent,
  LineChartComponent
];

@NgModule({
  imports: [
    NbTabsetModule,
    NbCardModule,
    NbSpinnerModule,
    NbMenuModule,
    NbButtonModule,
    NbAlertModule,
    NgxChartsModule,
    NbUserModule,
    NbSelectModule,
    NbActionsModule,
    NbChatModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbIconModule,
    NgxDatatableModule,
    CommonModule,
    GeneralComponentsModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [NbChatCustomMessageService],

  exports:[...[COMPONENTS]]
})
export class ComponentsDashboardModule {
}
