/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PersonalDataModelDto } from '../models/personal-data-model-dto';

@Injectable({
  providedIn: 'root',
})
export class PersonalDataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation personalDataControllerGetPersonalData
   */
  static readonly PersonalDataControllerGetPersonalDataPath = '/personal-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personalDataControllerGetPersonalData()` instead.
   *
   * This method doesn't expect any request body.
   */
  personalDataControllerGetPersonalData$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PersonalDataModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonalDataService.PersonalDataControllerGetPersonalDataPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonalDataModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personalDataControllerGetPersonalData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personalDataControllerGetPersonalData(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<PersonalDataModelDto> {

    return this.personalDataControllerGetPersonalData$Response(params).pipe(
      map((r: StrictHttpResponse<PersonalDataModelDto>) => r.body as PersonalDataModelDto)
    );
  }

  /**
   * Path part for operation personalDataControllerGetPersonalDataByUuid
   */
  static readonly PersonalDataControllerGetPersonalDataByUuidPath = '/personal-data/admin/profile/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personalDataControllerGetPersonalDataByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  personalDataControllerGetPersonalDataByUuid$Response(params: {
    workspace?: string;

    /**
     * email to filter by
     */
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PersonalDataModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonalDataService.PersonalDataControllerGetPersonalDataByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonalDataModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personalDataControllerGetPersonalDataByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personalDataControllerGetPersonalDataByUuid(params: {
    workspace?: string;

    /**
     * email to filter by
     */
    email: string;
    context?: HttpContext
  }
): Observable<PersonalDataModelDto> {

    return this.personalDataControllerGetPersonalDataByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<PersonalDataModelDto>) => r.body as PersonalDataModelDto)
    );
  }

  /**
   * Path part for operation personalDataControllerUserUpdatePersonalData
   */
  static readonly PersonalDataControllerUserUpdatePersonalDataPath = '/personal-data/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personalDataControllerUserUpdatePersonalData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personalDataControllerUserUpdatePersonalData$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: PersonalDataModelDto
  }
): Observable<StrictHttpResponse<PersonalDataModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonalDataService.PersonalDataControllerUserUpdatePersonalDataPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonalDataModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personalDataControllerUserUpdatePersonalData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personalDataControllerUserUpdatePersonalData(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: PersonalDataModelDto
  }
): Observable<PersonalDataModelDto> {

    return this.personalDataControllerUserUpdatePersonalData$Response(params).pipe(
      map((r: StrictHttpResponse<PersonalDataModelDto>) => r.body as PersonalDataModelDto)
    );
  }

  /**
   * Path part for operation personalDataControllerAdminUpdatePersonalData
   */
  static readonly PersonalDataControllerAdminUpdatePersonalDataPath = '/personal-data/admin/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personalDataControllerAdminUpdatePersonalData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personalDataControllerAdminUpdatePersonalData$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: PersonalDataModelDto
  }
): Observable<StrictHttpResponse<PersonalDataModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, PersonalDataService.PersonalDataControllerAdminUpdatePersonalDataPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonalDataModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personalDataControllerAdminUpdatePersonalData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  personalDataControllerAdminUpdatePersonalData(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: PersonalDataModelDto
  }
): Observable<PersonalDataModelDto> {

    return this.personalDataControllerAdminUpdatePersonalData$Response(params).pipe(
      map((r: StrictHttpResponse<PersonalDataModelDto>) => r.body as PersonalDataModelDto)
    );
  }

}
