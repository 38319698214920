<div  class="w-100 h-100 overflow-auto d-flex flex-column justify-content-between p-4">
  <div class="d-flex justify-content-end">
    <img (click)="closeModalInfo()" class="close-gray" src="assets/imgs/close-thin-gray.svg">
  </div>

  <!-- Contenido principal -->
  <div *ngIf="!isLoading; else spinner" class="d-flex flex-column flex-grow-1 overflow-auto px-3">
    <h2 class="d-flex title justify-content-center">{{ data?.modalTitle }}</h2>

    <!-- Contenedor que ocupa el espacio restante y tiene scroll -->
    <div class="content flex-grow-1 overflow-auto px-3" #contentContainer>
      <div class="w-100 d-flex flex-column justify-content-center">
      <div [innerHTML]="data?.modalText"></div>
      </div>
      <div #auxDiv></div>
    </div>  
  </div>
</div>

<ng-template #spinner>
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <nb-spinner status="info" size="giant" [message]="''"></nb-spinner>
  </div>
</ng-template>
