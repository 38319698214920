/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MailConfigModelDto } from '../models/mail-config-model-dto';
import { MailConfigObjectsDto } from '../models/mail-config-objects-dto';
import { MailTestDto } from '../models/mail-test-dto';

@Injectable({
  providedIn: 'root',
})
export class MailConfigService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation mailConfigControllerGetMailConfigs
   */
  static readonly MailConfigControllerGetMailConfigsPath = '/mail-config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerGetMailConfigs()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigs$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MailConfigModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerGetMailConfigsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MailConfigModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerGetMailConfigs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigs(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<MailConfigModelDto>> {

    return this.mailConfigControllerGetMailConfigs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MailConfigModelDto>>) => r.body as Array<MailConfigModelDto>)
    );
  }

  /**
   * Path part for operation mailConfigControllerCreateMailConfig
   */
  static readonly MailConfigControllerCreateMailConfigPath = '/mail-config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerCreateMailConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerCreateMailConfig$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: MailConfigModelDto
  }
): Observable<StrictHttpResponse<MailConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerCreateMailConfigPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MailConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerCreateMailConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerCreateMailConfig(params: {
    workspace?: string;
    context?: HttpContext
    body: MailConfigModelDto
  }
): Observable<MailConfigModelDto> {

    return this.mailConfigControllerCreateMailConfig$Response(params).pipe(
      map((r: StrictHttpResponse<MailConfigModelDto>) => r.body as MailConfigModelDto)
    );
  }

  /**
   * Path part for operation mailConfigControllerGetMailConfigByUuid
   */
  static readonly MailConfigControllerGetMailConfigByUuidPath = '/mail-config/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerGetMailConfigByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MailConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerGetMailConfigByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MailConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerGetMailConfigByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<MailConfigModelDto> {

    return this.mailConfigControllerGetMailConfigByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<MailConfigModelDto>) => r.body as MailConfigModelDto)
    );
  }

  /**
   * Path part for operation mailConfigControllerUpdateMailConfig
   */
  static readonly MailConfigControllerUpdateMailConfigPath = '/mail-config/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerUpdateMailConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerUpdateMailConfig$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: MailConfigModelDto
  }
): Observable<StrictHttpResponse<MailConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerUpdateMailConfigPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MailConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerUpdateMailConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerUpdateMailConfig(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: MailConfigModelDto
  }
): Observable<MailConfigModelDto> {

    return this.mailConfigControllerUpdateMailConfig$Response(params).pipe(
      map((r: StrictHttpResponse<MailConfigModelDto>) => r.body as MailConfigModelDto)
    );
  }

  /**
   * Path part for operation mailConfigControllerDeleteMailConfig
   */
  static readonly MailConfigControllerDeleteMailConfigPath = '/mail-config/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerDeleteMailConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerDeleteMailConfig$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerDeleteMailConfigPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerDeleteMailConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerDeleteMailConfig(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.mailConfigControllerDeleteMailConfig$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mailConfigControllerTestMailConfigByUuid
   */
  static readonly MailConfigControllerTestMailConfigByUuidPath = '/mail-config/{uuid}/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerTestMailConfigByUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerTestMailConfigByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to test by
     */
    uuid: string;
    context?: HttpContext
    body: MailTestDto
  }
): Observable<StrictHttpResponse<MailConfigModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerTestMailConfigByUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MailConfigModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerTestMailConfigByUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mailConfigControllerTestMailConfigByUuid(params: {
    workspace?: string;

    /**
     * uuid to test by
     */
    uuid: string;
    context?: HttpContext
    body: MailTestDto
  }
): Observable<MailConfigModelDto> {

    return this.mailConfigControllerTestMailConfigByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<MailConfigModelDto>) => r.body as MailConfigModelDto)
    );
  }

  /**
   * Path part for operation mailConfigControllerGetMailConfigurationObjects
   */
  static readonly MailConfigControllerGetMailConfigurationObjectsPath = '/mail-config/providers/configurations';

  /**
   * Returns all mail configuration variables depending its provider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailConfigControllerGetMailConfigurationObjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigurationObjects$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MailConfigObjectsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MailConfigService.MailConfigControllerGetMailConfigurationObjectsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MailConfigObjectsDto>;
      })
    );
  }

  /**
   * Returns all mail configuration variables depending its provider.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mailConfigControllerGetMailConfigurationObjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailConfigControllerGetMailConfigurationObjects(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<MailConfigObjectsDto> {

    return this.mailConfigControllerGetMailConfigurationObjects$Response(params).pipe(
      map((r: StrictHttpResponse<MailConfigObjectsDto>) => r.body as MailConfigObjectsDto)
    );
  }

}
