/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Base64FileDto } from '../models/base-64-file-dto';
import { GroupModelDto } from '../models/group-model-dto';
import { ProfileModelDto } from '../models/profile-model-dto';

@Injectable({
  providedIn: 'root',
})
export class CampaignGroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation campaignGroupControllerGetGroups
   */
  static readonly CampaignGroupControllerGetGroupsPath = '/campaign-group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerGetGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroups$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Group name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GroupModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerGetGroupsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GroupModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerGetGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroups(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Group name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<GroupModelDto>> {

    return this.campaignGroupControllerGetGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GroupModelDto>>) => r.body as Array<GroupModelDto>)
    );
  }

  /**
   * Path part for operation campaignGroupControllerCreateGroup
   */
  static readonly CampaignGroupControllerCreateGroupPath = '/campaign-group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerCreateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerCreateGroup$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: GroupModelDto
  }
): Observable<StrictHttpResponse<GroupModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerCreateGroupPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerCreateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerCreateGroup(params: {
    workspace?: string;
    context?: HttpContext
    body: GroupModelDto
  }
): Observable<GroupModelDto> {

    return this.campaignGroupControllerCreateGroup$Response(params).pipe(
      map((r: StrictHttpResponse<GroupModelDto>) => r.body as GroupModelDto)
    );
  }

  /**
   * Path part for operation campaignGroupControllerGetGroupByUuid
   */
  static readonly CampaignGroupControllerGetGroupByUuidPath = '/campaign-group/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerGetGroupByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroupByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GroupModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerGetGroupByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerGetGroupByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroupByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<GroupModelDto> {

    return this.campaignGroupControllerGetGroupByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<GroupModelDto>) => r.body as GroupModelDto)
    );
  }

  /**
   * Path part for operation campaignGroupControllerUpdateGroup
   */
  static readonly CampaignGroupControllerUpdateGroupPath = '/campaign-group/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerUpdateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerUpdateGroup$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: GroupModelDto
  }
): Observable<StrictHttpResponse<GroupModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerUpdateGroupPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerUpdateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerUpdateGroup(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: GroupModelDto
  }
): Observable<GroupModelDto> {

    return this.campaignGroupControllerUpdateGroup$Response(params).pipe(
      map((r: StrictHttpResponse<GroupModelDto>) => r.body as GroupModelDto)
    );
  }

  /**
   * Path part for operation campaignGroupControllerDeleteGroup
   */
  static readonly CampaignGroupControllerDeleteGroupPath = '/campaign-group/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerDeleteGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerDeleteGroup$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerDeleteGroupPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerDeleteGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerDeleteGroup(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.campaignGroupControllerDeleteGroup$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation campaignGroupControllerImportProfilesCsv
   */
  static readonly CampaignGroupControllerImportProfilesCsvPath = '/campaign-group/{uuid}/import-profiles-csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerImportProfilesCsv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerImportProfilesCsv$Response(params: {
    workspace?: string;

    /**
     * uuid to import
     */
    uuid: string;
    context?: HttpContext
    body: Base64FileDto
  }
): Observable<StrictHttpResponse<GroupModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerImportProfilesCsvPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerImportProfilesCsv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignGroupControllerImportProfilesCsv(params: {
    workspace?: string;

    /**
     * uuid to import
     */
    uuid: string;
    context?: HttpContext
    body: Base64FileDto
  }
): Observable<GroupModelDto> {

    return this.campaignGroupControllerImportProfilesCsv$Response(params).pipe(
      map((r: StrictHttpResponse<GroupModelDto>) => r.body as GroupModelDto)
    );
  }

  /**
   * Path part for operation campaignGroupControllerGetGroupProfilesByGroupUuid
   */
  static readonly CampaignGroupControllerGetGroupProfilesByGroupUuidPath = '/campaign-group/{uuid}/profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignGroupControllerGetGroupProfilesByGroupUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroupProfilesByGroupUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignGroupService.CampaignGroupControllerGetGroupProfilesByGroupUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignGroupControllerGetGroupProfilesByGroupUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignGroupControllerGetGroupProfilesByGroupUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<ProfileModelDto>> {

    return this.campaignGroupControllerGetGroupProfilesByGroupUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileModelDto>>) => r.body as Array<ProfileModelDto>)
    );
  }

}
