import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "ngx-modal-legal-advice",
  templateUrl: "./modal-legal-advice.component.html",
  styleUrls: ["./modal-legal-advice.component.scss"],
})
export class ModalLegalAdviceComponent implements OnInit, OnDestroy {
  isFinished: boolean = false;

  @ViewChild("contentContainer", { static: false })
  contentContainer!: ElementRef;
  @ViewChild("auxDiv", { static: false }) auxDiv!: ElementRef;

  legalAdvice = `     
  <p class="bold">TITULARIDAD DEL SITIO WEB</p>
  <p>El presente sitio web (en adelante, el Sitio Web) es de Ticsmart SL Techherox SLU Soft Com Informatica SL UTE (en adelante, <span class="bold">la UTE</span>).</p>
  
  <p>El(los) nombre(s) de dominio(s) a través del cual(es) Vd. ha accedido al Sitio Web es(son) titularidad de la UTE. Dicho(s) nombre(s) de dominio(s) no podrá(n) ser utilizado(s) en conexión con otros contenidos, productos y/o servicios que no sean titularidad de la UTE, ni de manera que puedan causar confusión entre los usuarios finales o el descrédito de la UTE.</p>

  <p>A los efectos del presente Aviso Legal, Usuarios son las personas que accedan al Sitio Web y/o que remitan una solicitud o un email al Sitio Web o que completen un formulario del Sitio Web o completen un proceso de registro.</p>

  <p>Puede ponerse en contacto con la UTE a través de los siguientes medios:</p>
  
  <ul>
    <li class="bold">Datos de contacto del responsable:</li>

    <ol>
      <li>Nombre completo: TICSMART, S.L. - TECHHEROX, S.L.U. - SOFTCOM INFORMÁTICA, S.L. Unión Temporal de Empresas, Ley 18/1982 de 26 de Mayo</li>
      
      <li>Domicilio social: 14900-Córdoba, Calle Jaime, nº 3</li>
      
      <li>CIF: U44860591</li>
    </ol>
  
    <li><span class="bold">Datos de contacto del Delegado de Protección de Datos:</span> puede contactar a través del correo electrónico: info@santiagomediano.com</li>        
  </ul>

  <p class="bold">OBJETO</p>

  <p>El presente Aviso Legal recoge los términos y condiciones que regulan: a) el acceso, navegación y uso del Sitio Web; b) las responsabilidades derivadas del uso del Sitio Web y la prestación y/o contratación de los productos o servicios que, en su caso, puedan ser ofrecidos a través del Sitio Web; y c) el suministro y uso del contenido del Sitio Web. Sin perjuicio de cualquier disposición en contrario, lo anterior no impide que la UTE pueda establecer condiciones particulares que regulen la utilización, prestación y/o contratación de productos o servicios que, en su caso, sean ofrecidos a los Usuarios a través del Sitio Web. En todo caso, dichas condiciones particulares formarán parte integrante del presente Aviso Legal.</p>
  
  <p>El mero acceso al Sitio Web, la cumplimentación de formularios, el envío de solicitudes de información, consultas, quejas, ofertas de contratación, curriculum vitae y, en general, cualquier acto de naturaleza similar a los anteriores realizados a través de los formularios y/o buzones electrónicos existentes en el Sitio Web implicará, por su parte, la aceptación sin reservas de todas y cada una de las normas integrantes del presente Aviso Legal y la adquisición de la consideración de Usuario del Sitio Web. En consecuencia, Vd. debe leer atentamente y conocer el contenido del presente Aviso Legal.</p>
  
  <p>En caso de ser ofrecidos, a través del Sitio Web, la utilización, prestación y/o contratación de productos o servicios, el hecho de ser utilizados y/o solicitados por el Usuario implicará, asimismo, la aceptación sin reservas de las condiciones particulares que, en su caso, hayan sido establecidas al efecto, y que formarán parte integrante del presente Aviso Legal.</p>

  <p class="bold">ACCESO Y USO DEL SITIO WEB</p>

  <p>El acceso al Sitio Web por parte de los Usuarios tiene carácter libre y gratuito.</p>

  <p>El mero acceso al Sitio Web no implica, en sí mismo, el establecimiento de ningún tipo de vínculo o relación comercial entre la UTE y el Usuario, salvo cuando se hayan establecido los medios oportunos para ello y el Usuario haya dado cumplimiento previo a los requisitos que, en su caso, sean establecidos. La inclusión en el Sitio Web de información relativa a tratamientos o productos o servicios ofrecidos por la UTE tiene exclusivamente fines informativos y publicitarios, salvo que sea establecida expresamente otra finalidad.</p>

  <p>Si para la utilización, prestación y/o contratación de algún producto o servicio ofrecido a través del Sitio Web, el Usuario debiera proceder a su registro, éste será responsable de aportar información veraz y lícita, garantizando la autenticidad de todos aquellos datos que introduzca a la hora de cumplimentar los formularios preestablecidos para acceder a los tratamiento o productos o servicios de que se trate. Si como consecuencia del registro, se dotara al Usuario de una contraseña, éste se compromete a hacer un uso diligente y a mantener en secreto la misma. En consecuencia, los Usuarios son responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que le sean suministrados, y se comprometen a no ceder su uso a terceros, ya sea temporal o permanente, ni a permitir su acceso a personas ajenas. Será responsabilidad exclusiva del Usuario la utilización y/o contratación de los productos o servicios por cualquier tercero ilegítimo que emplee a tal efecto una contraseña a causa de una actuación culposa o no diligente o de la pérdida de la misma por el Usuario.</p>

  <p>En todo caso, el acceso, navegación y uso del Sitio Web y, en su caso, el uso o contratación de los servicios o productos que sean ofrecidos a través del mismo se hace bajo la única y exclusiva responsabilidad del Usuario, por lo que éste se compromete a observar diligente y fielmente cualquier instrucción adicional, impartida por la UTE o por personal autorizado de la UTE, relativa al uso del Sitio Web y sus contenidos.</p>

  <p>Por lo tanto, el Usuario se obliga a usar los contenidos, productos y servicios de forma diligente, correcta y lícita, de conformidad con la legislación vigente y, en particular, se compromete a abstenerse de:</p>

  <ol>
    <li>Utilizarlos con fines o efectos contrarios a la ley, a la moral, a las buenas costumbres generalmente aceptadas o al orden público y a las instrucciones recibidas de la UTE.</li>
    
    <li>Utilizarlos con fines lesivos de los legítimos derechos de terceros.</li>
    
    <li>Acceder y/o utilizar el Sitio Web para fines profesionales ni incorporar, como actividad empresarial propia, los servicios y contenidos del Sitio Web.</li>
    
    <li>Emplear los contenidos y productos y, en particular, la información de cualquier clase obtenida a través del Sitio Web o de los servicios para remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como abstenerse de comercializar o divulgar de cualquier modo dicha información.</li>
  </ol>
  
  <p>Queda expresamente prohibido el uso o aplicación de cualesquiera recursos técnicos, lógicos o tecnológicos en virtud de los cuales los Usuarios puedan beneficiarse, directa o indirectamente, con o sin lucro de la explotación no autorizada de los contenidos y/o servicios del Sitio Web.</p>

  <p class="bold">RENUNCIA DE GARANTÍAS</p>

  <p>La UTE no se hace responsable y, en la máxima medida permitida por la legislación aplicable, excluye cualesquiera garantías, expresas o implícitas, relativas a la mercantilidad, idoneidad para un fin concreto, no cumplimiento, exactitud, fiabilidad, integridad o actualidad de los contenidos, servicios, productos, textos, gráficos, enlaces u otros elementos incluidos en el sitio web, o los resultados obtenidos de acceder y usar el sitio web y/o el contenido del mismo.</p>
  
  <p>En consecuencia, la UTE no garantiza ni se hace responsable de:</p>

  <ol>        
    <li>la continuidad de los contenidos del sitio web y/o la falta de disponibilidad o accesibilidad al sitio web o continuidad técnica del mismo;</li>
    
    <li>la ausencia de errores en dichos contenidos o productos o que los posibles errores serán corregidos;</li>
    
    <li>la ausencia de virus y demás componentes dañinos en el sitio web o en el servidor que lo suministra;</li>
    
    <li>la invulnerabilidad del sitio web y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo;</li>
    
    <li>en su caso, la falta de utilidad o rendimiento de los contenidos o servicios del sitio web;</li>
    
    <li>los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que la UTE establece en el sitio web o a través de la vulneración de los sistemas de seguridad del mismo;</li>
    
    <li>cualesquiera otros daños que pudieran ser causados por motivos inherentes al no funcionamiento o al funcionamiento defectuoso del sitio web o de los sitios web a los que, en su caso, se hayan podido establecer links.</li>
  </ol>
  
  <p>La UTE aplica medidas razonables para evitar errores en los contenidos publicados en el Sitio Web. Los contenidos ofrecidos a través del Sitio Web se actualizan periódicamente y la UTE se reserva el derecho de modificarlos en cualquier momento. La UTE se hace responsable de las consecuencias que pudieran derivarse de los errores en los contenidos y/o servicios prestados por terceros en el sitio web.</p>

  <p>Queda totalmente prohibida cualquier comunicación o transmisión de contenidos al sitio web que infrinja los derechos de terceros y/o cuyo contenido sea amenazante, obsceno, difamatorio, pornográfico, xenófobo, atentatorio contra la dignidad personal o los derechos de los menores o contrario a la legislación vigente, así como cualquier conducta del usuario que incite o constituya una infracción penal.</p>
  
  <p>Asimismo queda prohibida la inclusión y comunicación de contenidos, por parte de los usuarios, que: i) sean falsos o inexactos y que induzcan o puedan inducir a error al resto de usuarios o al personal de la UTE; ii) puedan infringir los derechos de propiedad intelectual o industrial, pertenecientes a terceras personas; iii) que menoscaben o desprestigien la fama o crédito de la UTE; iv) que sean considerados como un supuesto de publicidad ilícita, engañosa o desleal; y/o v) que incorporen virus o cualquier otro elemento electrónico que pudiese dañar o impedir el funcionamiento del sitio web, de la red, equipos informáticos de la UTE o terceros y/o el acceso al sitio web del resto de usuarios.</p>

  <p class="bold">LIMITACIÓN DE RESPONSABILIDAD E INDEMNIZACIÓN</p>

  <p>El Usuario se compromete a mantener a la UTE y a cualquiera de sus filiales, sucursales, funcionarios, directores, empleados y agentes indemnes frente a cualquier reclamación, responsabilidad, costes y gastos, incluyendo honorarios de abogados que surjan de cualquier manera del uso del Sitio Web, la colocación o transmisión de cualquier mensaje, contenido, información, software u otros materiales a través del Sitio Web o por infracción de la ley o de los términos y condiciones contenidos en este Aviso Legal.</p>

  <p class="bold">CANCELACIÓN DEL ACCESO Y USO</p>

  <p>La UTE podrá, a su sola discreción, denegar, retirar, suspender y/o bloquear en cualquier momento y sin necesidad de previo aviso el acceso al Sitio Web a aquellos usuarios que incumplan este Aviso Legal, pudiendo eliminar su registro y toda la información y archivos relativos a la misma. La UTE no asumirá responsabilidad alguna frente al usuario por la cancelación del acceso al sitio web por las causas previstas en este párrafo.</p>

  <p class="bold">DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</p>

  <p>Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los Contenidos y, en particular, queda prohibido modificar, copiar, reproducir, comunicar públicamente, transformar o distribuir de cualquier forma la totalidad o parte de los Contenidos incluidos en el Sitio Web, para propósitos públicos o comerciales, si no se cuenta con la autorización previa, expresa y por escrito de la UTE o, en su caso, del titular de los derechos a que corresponda. A título enunciativo, queda prohibido el uso de cualquier tecnología para extraer y recopilar información y contenidos del Sitio Web.</p>
  
  <p>Las referencias a nombres y marcas comerciales o registradas, logotipos u otros signos distintivos, ya sean titularidad de la UTE o de terceras empresas, llevan implícitas la prohibición sobre su uso sin el consentimiento de la UTE o de sus legítimos propietarios. En ningún momento, salvo manifestación expresa el acceso o uso del Sitio Web y/o de sus Contenidos, confiere al usuario derecho alguno sobre las marcas, logotipos y/o signos distintivos en él incluidos protegidos por Ley.</p>

  <p class="bold">ENLACES</p>

  <p class="bold">Enlaces desde el Sitio Web a otras páginas web</p>

  <p>La UTE puede ofrecer enlaces, directa o indirectamente, a recursos o páginas web de Internet que se encuentran fuera del Sitio Web. La presencia de estos enlaces en el Sitio Web tiene una finalidad informativa, no constituyendo en ningún caso una invitación a la contratación de productos y/o servicios que se ofrezcan o puedan ofrecer en las páginas web de destino ni implica la existencia de vínculo o relación mercantil o de dependencia con la persona o entidad titular de la página web enlazada. En estos casos, la UTE no será responsable de establecer las condiciones generales y particulares a tener en cuenta en la utilización, prestación o contratación de estos servicios por terceros y, por lo tanto, no podrá ser considerado responsable de los mismos.</p>
  
  <p>La UTE no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otras páginas web a las que se puedan establecer enlaces desde el Sitio Web. En consecuencia, la UTE no podrá asumir ningún tipo de responsabilidad por cualquier aspecto relativo a las páginas web a las que se pudieran establecer un enlace desde el Sitio Web, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.</p>
  
  <p>Ello no obstante, en caso de que la UTE llegue a tener conocimiento efectivo de que la actividad o la información a la que se remite desde dichos enlaces es ilícita, constitutiva de delito o puede lesionar bienes o derechos de un tercero, actuará con la diligencia necesaria para suprimir o inutilizar el enlace correspondiente a la mayor brevedad posible.</p>
  
  <p>Asimismo, si los Usuarios tuvieran conocimiento efectivo de la ilicitud de actividades desarrolladas a través de estas páginas web de terceros, deberán comunicarlo inmediatamente a la UTE a los efectos de que se proceda a deshabilitar el enlace de acceso a la misma.</p>

  <p class="bold">Enlaces desde otras páginas web al Sitio Web</p>

  <p>Si cualquier Usuario, entidad o página web deseara establecer algún tipo de enlace con destino a este sitio Web deberá atenerse a las siguientes estipulaciones:</p>

  <ol>
    <li>Deberá recabar la autorización previa, expresa y por escrito de la UTE.</li>
    
    <li>El enlace solamente se podrá dirigir a la página principal del Sitio Web, salvo que expresamente se autorice cosa distinta.</li>
    
    <li>El enlace debe ser absoluto y completo, es decir, debe llevar al Usuario, mediante un clic, a la página principal y debe abarcar completamente toda la extensión de la pantalla de la página principal del Sitio Web. En ningún caso, salvo que la UTE autorice cosa distinta, la página web desde la que se realice el enlace podrá reproducir, de cualquier manera el Sitio Web, incluirlo como parte de su web o dentro de uno de sus "frames" o crear un "browser" sobre cualquiera de las páginas del Sitio Web.</li>
    
    <li>En UTE página web desde la que se establece el enlace no se podrá declarar de ninguna manera que la UTE ha autorizado tal enlace, sin que así haya sido. Si la entidad que realiza el enlace desde su página al Sitio Web correctamente deseará incluir en su página web la marca, denominación, nombre comercial, rótulo, logotipo, o cualquier otro signo identificativo de la UTE y/o del Sitio Web, deberá contar previamente con la autorización previa, expresa y por escrito de la UTE.</li>
    
    <li>En todo caso, la UTE prohíbe el establecimiento de un enlace al Sitio Web desde aquellas páginas web que contengan materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos, y en general, que contravengan la moral, el orden público, la legislación vigente, las normas sociales generalmente aceptadas o sean lesivos de legítimos derechos de terceros.</li>
  </ol>

  <p class="bold">POLÍTICA DE PRIVACIDAD</p>

  <p>Cuando sea necesario que el Usuario se registre o facilite datos personales (entre otros supuestos, para acceder a servicios; solicitar información; remitir consultas, quejas o solicitudes de contratación; enviar currículum vitae), el Usuario será advertido de la necesidad de facilitar sus datos personales.</p>
  
  <p>En todo caso, la recogida y el tratamiento de los datos personales se llevará a cabo con sujeción a los principios y obligaciones de la normativa aplicable en materia de protección de datos, y de conformidad con lo previsto en la Política de Privacidad, la cual forma parte integrante de este Aviso Legal.</p>

  <p class="bold">USO DE LOS SERVICIOS Y ACTIVIDADES</p>

  <ol>
    <li>Los servicios y actividades descritos en esta web están destinados exclusivamente a usuarios mayores de edad.</li>        
    
    <li>En caso de que un usuario con algún tipo de discapacidad o que esté tutelado desee utilizar estos servicios, deberá contar con la autorización expresa de su tutor legal.</li>        
    
    <li>Si se detecta que los servicios han sido utilizados por personas que no cumplan con los requisitos mencionados en los puntos (i) o (ii), la UTE se reserva el derecho de eliminar la información relacionada y de no prestar el servicio, conforme a lo establecido por la legislación vigente y con todas las garantías que esta ofrece.</li>        
  </ol>

  <p class="bold">DURACIÓN Y MODIFICACIÓN</p>

  <p>La UTE se reserva el derecho de modificar cualesquiera términos y condiciones del presente Aviso Legal sin necesidad de previo aviso y/o las condiciones particulares que, en su caso, hayan sido establecidas para la utilización y/o contratación de los productos o servicios prestados a través del Sitio Web, cuando lo considere oportuno, por motivos de negocio o con la finalidad de adecuarse a los cambios legislativos y tecnológicos, siendo válidas y surtiendo sus efectos desde la publicación en el Sitio Web.</p>
  
  <p>La vigencia temporal de estas condiciones de uso coincide, por lo tanto, con el tiempo de su exposición, hasta que sean modificadas total o parcialmente, momento en el cual pasarán a tener vigencia las condiciones de uso modificadas.</p>
  
  <p>La UTE podrá dar por terminado, suspender o interrumpir, en cualquier momento sin necesidad de preaviso, el acceso a los contenidos publicados, sin posibilidad por parte del Usuario de exigir indemnización alguna. Tras dicha extinción, seguirán vigentes las prohibiciones de uso de los contenidos, expuestas anteriormente en el presente Aviso Legal.</p>

  <p class="bold">COMUNICACIONES</p>

  <p>Cualquier comunicación entre la UTE y el Usuario, éste deberá dirigirse a la UTE a la dirección postal y/o electrónica arriba indicadas. En todo caso, las comunicaciones de la UTE hacia el Usuario se realizarán de conformidad con los datos de contacto aportados o facilitados. El Usuario acepta expresamente la utilización del correo electrónico como procedimiento válido para el intercambio de información y la remisión de comunicaciones entre y/o con la UTE.</p>

  <p class="bold">GENERALIDADES</p>

  <p>Los encabezamientos de las distintas cláusulas son sólo informativos, y no afectarán, calificarán o modificarán la interpretación del Aviso Legal.</p>
  
  <p>En caso de existir discrepancia entre lo establecido en este Aviso Legal y las condiciones particulares que, en su caso, puedan ser establecidas respecto de los productos o servicios ofrecidos en el Sitio Web, prevalecerá lo dispuesto en las condiciones particulares.</p>
  
  <p>En el caso de que cualquier disposición o disposiciones de este Aviso Legal fuera(n) considerada(s) nula(s) o inaplicable(s), en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las otras disposiciones del Aviso Legal ni a las condiciones particulares que, en su caso, hayan podido ser establecidas.</p>
  
  <p>El no ejercicio o ejecución por parte de la UTE de cualquier derecho o disposición contenida en este Aviso Legal no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.</p>

  <p class="bold">LEGISLACIÓN APLICABLE Y TRIBUNALES COMPETENTES</p>

  <p>El presente Aviso Legal y cualquier relación que surja de su aceptación o se relacione con el mismo se regirán exclusivamente por la legislación española que resulte de aplicación.</p>
  
  <p>Los tribunales competentes para resolver cualquier controversia que surja de o esté relacionada con este Aviso Legal y/o cualquier relación que surja de su aceptación vendrán determinados según legislación aplicable y se corresponderán con los del domicilio de la UTE.</p>

  <p class="bold">Última versión: septiembre 2024</p>
`;
  privacyPolicy = `
  <p class="bold">INFORMACIÓN SOBRE EL TRATAMIENTO DE DATOS PERSONALES Y SOLICITUD DE CONSENTIMIENTO</p>
  <p>En cumplimiento de lo dispuesto en el <span class="bold">Reglamento General de Protección de Datos (Reglamento (UE) 2016/679, en adelante "RGPD") y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (en adelante "LOPDGDD"),</span> le informamos que, al participar en el presente <span>cuestionario de personalidad</span>, sus datos personales y las respuestas proporcionadas serán tratados bajo las siguientes condiciones:</p>
  <ol>
    <li class="bold">Responsable del Tratamiento</li>
      <ul>
        <li><span class="bold">Responsable:</span> TICSMART, S.L. - TECHHEROX, S.L.U. - SOFTCOM INFORMÁTICA, S.L. Unión Temporal de Empresas, Ley 18/1982 de 26 de Mayo</li>
        <li><span class="bold">CIF:</span> U44860591</li>
        <li><span class="bold">Domicilio social:</span> 14900-Córdoba, Calle Jaime, nº 3</li>
        <li><span class="bold">Correo electrónico de contacto:</span> info@eveintelligence.io</li>
        <li><span class="bold">Delegado de Protección de Datos:</span> info@santiagomediano.com</li>
      </ul>
    <li class="bold">Finalidad del Tratamiento</li>
  
    <p>La finalidad principal del tratamiento de los datos personales recabados mediante este cuestionario es:</p>

    <ul>
      <li><span class="bold">Analizar los rasgos de personalidad que son determinantes en la reacción ante un ciberataque</span> mediante las respuestas aportadas.</li>

      <li><span class="bold">Entrenar el algoritmo del proyecto</span> para asegurar que este sea eficaz en la detección de patrones de comportamiento relacionados con ciberataques.</li>

      <li><span class="bold">Informarle de los resultados de su test de personalidad</span> a través del correo electrónico indicado en el proceso de inscripción.</li>
    </ul>

    <p>Le informamos que, en el tratamiento de sus datos personales, se utilizarán herramientas de inteligencia artificial (IA), entendidas estas como un sistema automatizado que, con objetivos explícitos o implícitos, infiere, a partir de los datos de entrada que recibe, cómo generar resultados tales como predicciones, contenidos, recomendaciones o decisiones, para el análisis y procesamiento de las respuestas (estas, las <span class="bold">“Herramientas de Inteligencia Artificial”).</span> Estas herramientas podrán implicar la toma de decisiones automatizadas, orientadas a identificar patrones de comportamiento relacionados con la reacción ante ciberataques. No obstante, le aseguramos que todas las decisiones automatizadas basadas en IA serán revisadas periódicamente para garantizar su exactitud y adecuación. La confidencialidad, así como la anonimización y/o pseudoanimización cuando éstas sean posibles, de sus datos serán mantenidas en todo momento.</p>
    
    <li class="bold">Base Jurídica para el Tratamiento</li>

    <p>El tratamiento de sus datos personales se basa en el consentimiento explícito que usted otorga mediante la aceptación de este documento, conforme al artículo 6.1.a del RGPD y al artículo 6 de la LOPDGDD.</p>
    
    <li class="bold">Datos Personales Tratados</li>

    <ul>
      <li><span class="bold">Datos identificativos:</span> Únicamente su dirección de correo electrónico, que se utilizará , única y exclusivamente, para informarle de los resultados del test de personalidad y para enviarles simulaciones de phishing con el fin de evaluar las hipótesis relacionadas con la personalidad y verificar de manera segura la susceptibilidad a posibles amenazas</li>
      
      <li><span class="bold">Respuestas al cuestionario:</span> Las respuestas proporcionadas serán tratadas de forma <span class="bold">anónima y confidencial,</span> sin que se pueda asociar ningún dato sensible o identificativo a las respuestas.</li>
    </ul>
    
    <li class="bold">Anonimización y Confidencialidad</li>

    <p>Le informamos que todas las respuestas al cuestionario serán tratadas de forma <span class="bold">totalmente anónima</span> y no se asociarán en ningún momento a datos personales identificativos. Su participación es confidencial, y la información proporcionada solo será utilizada para los fines mencionados. No se realizarán tratamientos adicionales fuera del ámbito del proyecto.</p>
    
    <p>En el uso de las Herramientas de Inteligencia Artificial, de acuerdo con el diseño de privacidad aplicado a éstas, nos obligamos a no aplicar éstas a los datos personales cuando ello pueda permitir la identificación directa o indirecta de un individuo por parte de terceros.</p>
    
    <li class="bold">Destinatarios de los Datos</li>

    <p>Los datos no serán cedidos a terceros, salvo obligación legal. No obstante, las respuestas anónimas podrán ser utilizadas para <span class="bold">fines de investigación y desarrollo del proyecto,</span> y podrán ser compartidas con terceros responsables del diseño y entrenamiento del algoritmo del proyecto, garantizando en todo momento la total anonimización de los datos.</p>

    <li class="bold">Plazo de Conservación de los Datos</li>

    <p>Los datos personales asociados a este proyecto se conservarán únicamente durante el tiempo necesario para comunicarle los resultados del test de personalidad. Las respuestas anónimas podrán ser conservadas indefinidamente con fines de investigación y para el entrenamiento continuo del algoritmo, sin posibilidad de identificar al participante.</p>
    
    <li class="bold">Ejercicio de Derechos</li>

    <p>Usted tiene derecho a:</p>

    <ul>
      <li><span class="bold">Acceder</span> a sus datos personales.</li>
      
      <li><span class="bold">Rectificar</span> los datos inexactos.</li>
      
      <li><span class="bold">Solicitar la supresión</span> de sus datos cuando estos ya no sean necesarios para los fines para los que fueron recabados.</li>
      
      <li><span class="bold">Limitar el tratamiento</span> de sus datos personales en determinadas circunstancias.</li>
      
      <li><span class="bold">Oponerse</span> al tratamiento de sus datos.</li>
      
      <li><span class="bold">Solicitar la portabilidad</span> de sus datos, en caso de ser aplicable.</li>
    </ul>

    <p>Para ejercer cualquiera de estos derechos, puede dirigirse al responsable del tratamiento mediante el correo electrónico indicado anteriormente, adjuntando una copia de su DNI o documento equivalente que permita acreditar su identidad.</p>
    
    <p>Adicionalmente y en relación al uso de IA en el tratamiento de sus datos personales y la toma de decisiones automatizadas, en caso de que dichas decisiones puedan afectar significativamente a su persona, usted tiene derecho a solicitar una revisión manual por parte de nuestro equipo, en conformidad con el artículo 22 del RGPD.</p>
    
    <p>Asimismo, si considera que sus derechos no han sido respetados, tiene derecho a presentar una reclamación ante la <span class="bold">Agencia Española de Protección de Datos (AEPD)</span> a través de su sitio web <a href="https://www.aepd.es" target="_blank">www.aepd.es</a>.</p>
    
    <li class="bold">Retirada del Consentimiento</li>

    <p>Usted puede retirar su consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. Para ello, deberá comunicarse con nosotros mediante los medios facilitados.</p>
    
    <li class="bold">Consentimiento Expreso</li>

    <p>Al participar en este cuestionario, usted declara haber leído y comprendido esta información sobre el tratamiento de sus datos personales y, mediante la aceptación del presente documento, <span class="bold">autoriza el tratamiento de los datos personales proporcionados con las finalidades descritas.</span></p>
  </ol>
  <p class='bold'>Acepto los términos de tratamiento de datos personales conforme al RGPD y la LOPDGDD, y consiento el uso de mis respuestas para entrenar el algoritmo del proyecto, así como para recibir los resultados del test de personalidad en mi correo electrónico.</p>
  `;
  
  data: {
    image: string;
    title: string;
    description: string;
    buttonText: string;
    checkboxText: string;
    modalTriggerText: string;
    modalTitle: string;
    modalText: string;
    modalConfirmButtonText: string;
  } = {
    image: "",
    title: "¿Empezamos?",
    description:
      "Falta un pequeño paso, antes de continuar necesitamos que leas y aceptes los Términos y Condiciones, y que confirmes tu edad.",
    buttonText: "Quiero continuar",
    checkboxText:
      "Acepto los Términos y Condiciones y confirmo tener 18 años o más",
    modalTriggerText: "Términos y Condiciones",
    modalTitle: "AVISO LEGAL WEB",
    modalText: this.legalAdvice,
    modalConfirmButtonText: "He leído los Términos y Condiciones",
  };

  @Output() modalRead: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.modifyAnswerText();
  }

  modifyAnswerText() {
    const modalTriggerText = "Política de Privacidad";
    if (this.data.modalText.includes(modalTriggerText)) {
      const highlightedText = this.data.modalText.replace(
        modalTriggerText,
        `<a class="modal-trigger">${modalTriggerText}</a>`
      );
      // Actualiza el answerText con el texto modificado
      this.data.modalText = highlightedText;
    }

    // Espera un ciclo de detección de cambios para asegurarse de que el DOM se haya actualizado
    setTimeout(() => {
      // Selecciona todos los span con la clase modal-trigger
      const modalTriggers = document.querySelectorAll(".modal-trigger");

      modalTriggers.forEach((trigger) => {
        // Usamos el Renderer2 para añadir el evento click
        this.renderer.listen(trigger, "click", () => {
          this.data.modalTitle = 'Términos y Condiciones';
          this.data.modalText = this.privacyPolicy;
          if (this.contentContainer) {
            //this.contentContainer.nativeElement.scrollTop = 0; // Opción 1: scroll hacia arriba
            // Alternativamente puedes usar scrollTo:
            this.contentContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' }); // Opción 2: scroll suave hacia arriba
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.closeModal.emit();
  }
  closeModalInfo() {
    this.closeModal.emit();
  }
}
