import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AdminDashboardDto,
  ProfilesGroupedByPersonalityVarDto,
  ProfilesGroupedByVulnerabilityLevelDto,
} from "../../../../../services/api/models";
import { ComponentsService } from "../../../../../services/services/components.service";
import { DashboardPieGraphComponent } from "../dashboard-pie-graph/dashboard-pie-graph.component";
import { BarChartComponent } from "../bar-chart/bar-chart.component";
import { MultipleLinearComponent } from "../multiple-linear/multiple-linear.component";
import { SidebarService } from "../../../../../services/services/sidebar.service";

@Component({
  selector: "ngx-graphs-tabset",
  templateUrl: "./graphs-tabset.component.html",
  styleUrls: ["./graphs-tabset.component.scss"],
})
export class GraphsTabsetComponent implements OnInit, AfterViewInit {
  @ViewChild("graphModal") graphModal: TemplateRef<any>;
  @ViewChildren(DashboardPieGraphComponent)
  pieGraphs: QueryList<DashboardPieGraphComponent>;
  @ViewChildren(BarChartComponent) barCharts: QueryList<BarChartComponent>;
  @ViewChildren(MultipleLinearComponent)
  multipleLineCharts: QueryList<MultipleLinearComponent>;
  activeTab: string;
  @Input() adminDataGraphs: AdminDashboardDto;
  @Input() colorScheme: any;

  isMenuVisible: boolean = false;

  constructor(
    private readonly componentsService: ComponentsService,
    public sidebarCustomService: SidebarService
  ) {}

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    this.sidebarCustomService.sidebarVisible$.subscribe(async (visible) => {
      this.resizeGraphs();
      this.isMenuVisible = visible;
    });
  }

  onTabChange(event) {
    this.activeTab = event?.tabTitle;
    console.log('event', this.activeTab)

    this.resizeGraphs();
  }

  private resizeGraphs() {
    this.barCharts.forEach((barChart) => barChart.onResize());
    this.pieGraphs.forEach((pieGraph) => pieGraph.onResize());
    this.multipleLineCharts.forEach((multipleLineChart) =>
      multipleLineChart.onResize()
    );
  }

  openBigGraphModal(
    graphData: Array<
      | ProfilesGroupedByVulnerabilityLevelDto
      | ProfilesGroupedByPersonalityVarDto
    >,
    isVulnerability: boolean = false,
    title: string,
    graphType: "pie" | "bar" | "multipleLine",
    isLinear: boolean = false,
    monthMode: boolean = true
  ) {
    this.componentsService.openModal(
      this.graphModal,
      {
        graphData: graphData,
        isVulnerability: isVulnerability,
        title: title,
        graphType: graphType,
        isLinear: isLinear,
        monthMode: monthMode,
      },
      true,
      "personality-modal"
    );
  }

  onModalClose(ref: any) {
    // Cierra la modal como lo estaba haciendo antes
    ref.close();

    // Redimensionamos de nuevo los gráficos
    this.resizeGraphs();
  }

  // openBigGraphModal(graphData: Array<ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto>, isVulnerability: boolean = false, title: string) {
  //   this.componentsService.openModal(this.graphModal, {graphData: graphData, isVulnerability: isVulnerability, title: title}, true, "personality-modal"  )
  // }
}
