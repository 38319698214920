import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxPlural' })
export class PluralPipe implements PipeTransform {

  transform(input: number, label: string, pluralLabel: string = ''): string {
    input = input || 0;
    return this.formatLabel(input, label, pluralLabel);
  }

  private formatLabel(input: number, label: string, pluralLabel: string): string {
    if (input === 1) {
      return `${input} ${label}`;
    } else {
      return pluralLabel ? `${input} ${pluralLabel}` : `${input} ${label}s`;
    }
  }
}
