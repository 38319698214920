<ng-container *ngIf="adminDataGraphs">
  <nb-tabset fullWidth class="w-100">
    <nb-tab tabTitle="Vulnerabilidad">
      <section class="section-tabs d-flex flex-column flex-xl-row p-5">
        <div class="cards-containers d-flex flex-column justify-content-around w-25">
          <nb-card (click)="openBigGraphModal()">
            <!-- Vulnerability Department -->
            <span>{{'Departamento'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="true"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentAndVulnerabilityLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <span>{{'Cargo'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="true"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyAndVulnerabilityLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
              <span>{{'Timeline NC'}}</span>
              <!-- <ngx-line-chart [colorScheme]="colorScheme"></ngx-line-chart> -->
              <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart> -->
          </nb-card>
          <!-- Vulnerability Position -->
        </div>
        <div id="central-pie-container" class="d-flex flex-column" #eggContainer>
          <span>{{'Perfiles por vulnerabilidad'}}</span>

          <ngx-dashboard-pie-graph [isVulnerability]="true" [isCentral]="true" [colorScheme]="colorScheme"
            [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph>
        </div>
        <div class="cards-containers d-flex flex-column justify-content-around w-25">
          <nb-card>
            <span>{{'Gráfico 4'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 5'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="true"></ngx-accumulated-bars-graph> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 6'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="true"></ngx-accumulated-bars-graph> -->

          </nb-card>
        </div>
      </section>
    </nb-tab>
    <nb-tab tabTitle="BIS">
      <section class="section-tabs d-flex flex-column flex-lg-row p-5">
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card class="">
            <span>{{'Departamento'}}</span>
            <!-- BIS Department -->
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentBisLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <!-- BIS Position -->
            <span>{{'Cargo'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyBisLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <span>{{'Timeline BIS'}}</span>
              <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-dashboard-area-chart> -->
              <ngx-line-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-line-chart> 
            </nb-card>
        </div>
        <div id="central-pie-container" class="d-flex flex-column" #eggContainer>
          <span>{{'Personalidad Global - BIS'}}</span>

          <ngx-dashboard-pie-graph [isCentral]="true" [colorScheme]="colorScheme"
            [graphData]="adminDataGraphs.profilesGroupedByBisLevel"></ngx-dashboard-pie-graph>
        </div>
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card>
            <span>{{'Gráfico 2'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 6'}}</span>
            <!-- <ngx-dashboard-pie-graph [isCentral]="false" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 5'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"></ngx-accumulated-bars-graph> -->
          </nb-card>
        </div>
      </section>
    </nb-tab>
    <nb-tab tabTitle="BAS">
      <section class="section-tabs d-flex flex-column flex-lg-row p-5">
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card class="">
            <!-- BAS Department -->
            <span>{{'Departamento'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentBasLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <!-- BAS Position -->
            <span>{{'Cargo'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyBasLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <span>{{'Timeline BAS'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-dashboard-area-chart> -->
            <ngx-line-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.basLevelTimeline"></ngx-line-chart> 
          </nb-card>
        </div>
        <div id="central-pie-container" class="d-flex flex-column" #eggContainer>
          <span>{{'Personalidad Global - BAS'}}</span>
          <ngx-dashboard-pie-graph [isCentral]="true" [colorScheme]="colorScheme"
            [graphData]="adminDataGraphs.profilesGroupedByBasLevel"></ngx-dashboard-pie-graph>
        </div>
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card>
            <span>{{'Gráfico 2'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 6'}}</span>
            <!-- <ngx-dashboard-pie-graph [isCentral]="false" [colorScheme]="colorScheme"
                  [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 5'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"></ngx-accumulated-bars-graph> -->
          </nb-card>
        </div>
      </section>
    </nb-tab>
    <nb-tab tabTitle="NC">
      <section class="section-tabs d-flex flex-column flex-lg-row p-5">
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card class="">
            <!-- NC Department -->
            <span>{{'Departamento'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentNcLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <!-- NC Position -->
            <span>{{'Cargo'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyNcLevel"></ngx-accumulated-bars-graph>
          </nb-card>
          <nb-card>
            <span>{{'Timeline NC'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-dashboard-area-chart> -->
            <ngx-line-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.ncLevelTimeline"></ngx-line-chart> 
          </nb-card>
        </div>
        <div id="central-pie-container" class="d-flex flex-column" #eggContainer>
          <span>{{'Personalidad Global - NC'}}</span>
          <ngx-dashboard-pie-graph [isCentral]="true" [colorScheme]="colorScheme"
            [graphData]="adminDataGraphs.profilesGroupedByNcLevel"></ngx-dashboard-pie-graph>
        </div>
        <div class="d-flex flex-column justify-content-around w-25">
          <nb-card>
            <span>{{'Gráfico 2'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 6'}}</span>
            <!-- <ngx-dashboard-pie-graph [isCentral]="false" [colorScheme]="colorScheme"
                [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph> -->
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 5'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"></ngx-accumulated-bars-graph> -->
          </nb-card>
        </div>
      </section>
    </nb-tab>
  </nb-tabset>
</ng-container>