import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ProfilesGroupedByPersonalityVarDto, ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';
import * as d3 from "d3";
import tippy from 'tippy.js';

@Component({
  selector: 'ngx-dashboard-pie-graph',
  templateUrl: './dashboard-pie-graph.component.html',
  styleUrls: ['./dashboard-pie-graph.component.scss']
})
export class DashboardPieGraphComponent implements OnInit, AfterViewInit {  
  
  @ViewChild('chartContainer') chartContainer:  ElementRef;
  
  @Input() colorScheme;
  @Input() isCentral: boolean = false;
  @Input() isVulnerability: boolean = false;
  @Input() mainContainerId: string;
  
  @Input() graphData: ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto;
  view: any[] = [300, 158];
  results: any;
  timerId;
  
  svg: any = null;
  margin = 40;
  width = 460;
  height = 400;
  
  constructor() { 
    
  }
  
  ngOnInit() {
    if (this.isCentral) {
      this.view = null;
    }
    
    if ( this.graphData ) {
      this.checkValues();
      
    }
    
  }
  
  ngAfterViewInit(): void {
    
    setTimeout(() => {
      
      this.width = this.chartContainer.nativeElement.offsetWidth;
      this.height = this.chartContainer.nativeElement.offsetHeight;
      
      this.renderSvg()
      
    }, 300);
    
  }
  
  renderSvg () {
    
    // Datos de prueba
    const data = [{"name":"Vulnerabilidad Alta","value":1,"extra":{"code":"high-vulnerability"}},{"name":"Vulnerabilidad Media","value":3,"extra":{"code":"medium-vulnerability"}},{"name":"Vulnerabilidad Baja","value":2,"extra":{"code":"low-vulnerability"}}];
    // this.results = [
    //   { "name": "Vulnerabilidad Alta", "value": 36, "extra": { "code": "high-vulnerability" } },
    //   { "name": "Vulnerabilidad Media", "value": 45, "extra": { "code": "medium-vulnerability" } },
    //   { "name": "Vulnerabilidad Baja", "value": 23, "extra": { "code": "low-vulnerability" } }
    // ];

    // this.results = [
    //   { "name": "Nivel alto", "value": 59, "extra": { "code": "high-vulnerability" } },
    //   { "name": "Nivel medio-alto", "value": 54, "extra": { "code": "medium-vulnerability" } },
    //   { "name": "Nivel medio-bajo", "value": 37, "extra": { "code": "low-vulnerability" } },
    //   { "name": "Nivel bajo", "value": 22, "extra": { "code": "low-vulnerability" } },

    // ];
    // Configura el radio del gráfico
    const radius = Math.min(this.width, this.height) / 2 - this.margin;
    
    // Crea el contenedor SVG con espacio adicional para la leyenda arriba
    this.svg = d3.select('#' + this.mainContainerId)
    .append("svg")
    .attr("width", this.width)
    .attr("height", this.height + 100)  // Espacio adicional para la leyenda
    .append("g")
    .attr("transform", `translate(${this.width / 2}, ${this.height / 2 + 50})`); // Ajuste de la posición vertical
    
    // Configura la escala de color
    const color = d3.scaleOrdinal()
    .domain(this.results.map(d => d.name))
    .range(this.colorScheme.domain);
    
    // Crea el generador de "pie"
    const pie = d3.pie()
    .sort(null)
    .value(d => d.value);
    
    // Genera los datos para el gráfico de tarta
    const data_ready = pie(this.results);
    
    // Crea el generador de "arco"
    const arcGenerator = d3.arc()
    .innerRadius(0)
    .outerRadius(radius);
    
    // Dibuja las secciones de la tarta y añade los eventos para el tooltip
    this.svg
    .selectAll('mySlices')
    .data(data_ready)
    .join('path')
    .attr('d', arcGenerator)
    .attr('fill', d => color(d.data.name))
    .attr("stroke", "#636e72")
    .style("stroke-width", "1px")
    .style("opacity", 0.7)
    .each(function (d) {
      if (window.innerWidth >= 768) {
        tippy(this, {
          content: `<strong>${d.data.name}:</strong> ${d.data.value}`,
          placement: 'right',
          theme: 'light',
          arrow: true,
          delay: [100, 50],
          allowHTML: true,
        });
      }
    })
    .on("mouseover", function (event, d) {
      
    })
    .on("mousemove", function (event, d) {
      
    })
    .on("mouseleave", function () {
      
    });
    
    if (window.innerWidth < 768) {
      const labelGroup = this.svg.append("g").attr("class", "labels");

  labelGroup.selectAll('allLabels')
    .data(data_ready.filter(d => d.data.value > 0)) // Excluir valores 0
    .join('text')
    .text(d => `${d.data.value}`) // Mostrar el valor directamente
    .attr('transform', function (d) {
      const pos = arcGenerator.centroid(d); // Posición central del arco
      return `translate(${pos[0]}, ${pos[1]})`;
    })
    .style("text-anchor", "middle") // Centrar horizontalmente
    .style("alignment-baseline", "middle") // Centrar verticalmente
    .style("font-size", "12px") // Tamaño del texto
    .style("font-weight", "bold"); // Resaltar el texto
    }
    
    // Crear contenedor de leyenda **encima** del gráfico
    /* const legendContainer = d3.select('#' + this.mainContainerId)
    .insert("div", "svg")  // Inserta el div de leyenda antes del SVG
    .attr("class", "legend-container")
    .style("width", this.width + "px")
    .style("display", "flex")
    .style("flex-direction", "column")
    .style("align-items", "center")
    .style("margin-bottom", "10px");  */
    
    // Agregar elementos de leyenda para cada segmento del gráfico
    /* legendContainer.selectAll(".legend-item")
    .data(this.results)
    .enter()
    .append("div")
    .attr("class", "legend-item")
    .style("display", "flex")
    .style("align-items", "center")
    .style("margin-bottom", "5px")
    .each(function (d, i) {
      // Añadir el color de la leyenda
      d3.select(this)
      .append("div")
      .style("width", "15px")
      .style("height", "15px")
      .style("background-color", color(d.name))
      .style("margin-right", "10px");
      
      // Añadir el texto de la leyenda
      d3.select(this)
      .append("span")
      .text(`${d.name}: ${d.value}`);
    }); */
    
  }
  
  public onResize () {
    
    this.timerId = setTimeout(() => {
      
      // Eliminamos el contenido del contenedor (de esta manera aseguramos que se eliminan también listeners)
      while ( this.chartContainer.nativeElement.firstChild ) {
        
        this.chartContainer.nativeElement.removeChild(this.chartContainer.nativeElement.firstChild);
        
      }
      
      this.width = this.chartContainer.nativeElement.offsetWidth;
      this.height = this.chartContainer.nativeElement.offsetHeight;
      
      this.renderSvg()
      
    }, 300);
    
  }
  
  ngOnDestroy(): void {
  }
  
  checkValues() {
    this.results = [];
    
    if (this.isVulnerability) {
      this.results.push({
        name: 'Vulnerabilidad Alta',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.highVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'high-vulnerability' }
      });
      
      this.results.push({
        name: 'Vulnerabilidad Media',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.mediumVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'medium-vulnerability' }
      });
      
      this.results.push({
        name: 'Vulnerabilidad Baja',
        value: Number((this.graphData as ProfilesGroupedByVulnerabilityLevelDto)?.lowVulnerabilityTotalProfileCount) || 0,
        extra: { code: 'low-vulnerability' }
      });
    } else {
      this.results.push({
        name: 'Alto Nivel',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.highLevelTotalProfileCount) || 0,
        extra: { code: 'high-level' }
      });
      
      this.results.push({
        name: 'Nivel Medio-Alto',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.midhighLevelTotalProfileCount) || 0,
        extra: { code: 'midhigh-level' }
      });
      
      this.results.push({
        name: 'Nivel Medio-Bajo',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.midlowLevelTotalProfileCount) || 0,
        extra: { code: 'midlow-level' }
      });
      
      this.results.push({
        name: 'Bajo Nivel',
        value: Number((this.graphData as ProfilesGroupedByPersonalityVarDto)?.lowLevelTotalProfileCount) || 0,
        extra: { code: 'low-level' }
      });
    }
  }
} 

