/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminDashboardDto } from '../models/admin-dashboard-dto';
import { Base64FileDto } from '../models/base-64-file-dto';
import { CompanyDataModelDto } from '../models/company-data-model-dto';
import { DashboardDto } from '../models/dashboard-dto';
import { ExtendedProfileModelDto } from '../models/extended-profile-model-dto';
import { PersonalityTextsDto } from '../models/personality-texts-dto';
import { UserStorytellingsDto } from '../models/user-storytellings-dto';
import { VulnerabilityAlertDto } from '../models/vulnerability-alert-dto';

@Injectable({
  providedIn: 'root',
})
export class MeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation meControllerMe
   */
  static readonly MeControllerMePath = '/me';

  /**
   * Gets loggued user information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerMe$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExtendedProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerMePath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExtendedProfileModelDto>;
      })
    );
  }

  /**
   * Gets loggued user information.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerMe(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<ExtendedProfileModelDto> {

    return this.meControllerMe$Response(params).pipe(
      map((r: StrictHttpResponse<ExtendedProfileModelDto>) => r.body as ExtendedProfileModelDto)
    );
  }

  /**
   * Path part for operation meControllerGetPersonalityTexts
   */
  static readonly MeControllerGetPersonalityTextsPath = '/me/personality-texts';

  /**
   * Gets the corresponding texts depending on the user personality.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetPersonalityTexts()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPersonalityTexts$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PersonalityTextsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerGetPersonalityTextsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonalityTextsDto>;
      })
    );
  }

  /**
   * Gets the corresponding texts depending on the user personality.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetPersonalityTexts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPersonalityTexts(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<PersonalityTextsDto> {

    return this.meControllerGetPersonalityTexts$Response(params).pipe(
      map((r: StrictHttpResponse<PersonalityTextsDto>) => r.body as PersonalityTextsDto)
    );
  }

  /**
   * Path part for operation meControllerGetPolicyPrivacy
   */
  static readonly MeControllerGetPolicyPrivacyPath = '/me/policy-privacy';

  /**
   * Gets the policy privacy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetPolicyPrivacy()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPolicyPrivacy$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyDataModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerGetPolicyPrivacyPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDataModelDto>;
      })
    );
  }

  /**
   * Gets the policy privacy.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetPolicyPrivacy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPolicyPrivacy(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<CompanyDataModelDto> {

    return this.meControllerGetPolicyPrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDataModelDto>) => r.body as CompanyDataModelDto)
    );
  }

  /**
   * Path part for operation meControllerGetPersonalVideo
   */
  static readonly MeControllerGetPersonalVideoPath = '/me/personality-video';

  /**
   * Gets the corresponding video depending on the user personality.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetPersonalVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPersonalVideo$Response(params: {
    workspace?: string;

    /**
     * Request a personality video
     */
    video: 'bis' | 'bas' | 'nc';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Base64FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerGetPersonalVideoPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('video', params.video, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Base64FileDto>;
      })
    );
  }

  /**
   * Gets the corresponding video depending on the user personality.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetPersonalVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetPersonalVideo(params: {
    workspace?: string;

    /**
     * Request a personality video
     */
    video: 'bis' | 'bas' | 'nc';
    context?: HttpContext
  }
): Observable<Base64FileDto> {

    return this.meControllerGetPersonalVideo$Response(params).pipe(
      map((r: StrictHttpResponse<Base64FileDto>) => r.body as Base64FileDto)
    );
  }

  /**
   * Path part for operation meControllerDashboard
   */
  static readonly MeControllerDashboardPath = '/me/dashboard';

  /**
   * Gets the user`s dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerDashboard$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerDashboardPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardDto>;
      })
    );
  }

  /**
   * Gets the user`s dashboard.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerDashboard(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<DashboardDto> {

    return this.meControllerDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardDto>) => r.body as DashboardDto)
    );
  }

  /**
   * Path part for operation meControllerAdminDashboard
   */
  static readonly MeControllerAdminDashboardPath = '/me/admin/dashboard';

  /**
   * Gets the admin`s dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerAdminDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAdminDashboard$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AdminDashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerAdminDashboardPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminDashboardDto>;
      })
    );
  }

  /**
   * Gets the admin`s dashboard.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerAdminDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerAdminDashboard(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<AdminDashboardDto> {

    return this.meControllerAdminDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<AdminDashboardDto>) => r.body as AdminDashboardDto)
    );
  }

  /**
   * Path part for operation meControllerGetUserStorytellings
   */
  static readonly MeControllerGetUserStorytellingsPath = '/me/storytellings';

  /**
   * Gets user`s storytellings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerGetUserStorytellings()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetUserStorytellings$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserStorytellingsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerGetUserStorytellingsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserStorytellingsDto>>;
      })
    );
  }

  /**
   * Gets user`s storytellings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerGetUserStorytellings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerGetUserStorytellings(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<Array<UserStorytellingsDto>> {

    return this.meControllerGetUserStorytellings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserStorytellingsDto>>) => r.body as Array<UserStorytellingsDto>)
    );
  }

  /**
   * Path part for operation meControllerInitialAlert
   */
  static readonly MeControllerInitialAlertPath = '/me/initial-alert';

  /**
   * Gets alerts at init.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meControllerInitialAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerInitialAlert$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<VulnerabilityAlertDto>> {

    const rb = new RequestBuilder(this.rootUrl, MeService.MeControllerInitialAlertPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VulnerabilityAlertDto>;
      })
    );
  }

  /**
   * Gets alerts at init.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meControllerInitialAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meControllerInitialAlert(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<VulnerabilityAlertDto> {

    return this.meControllerInitialAlert$Response(params).pipe(
      map((r: StrictHttpResponse<VulnerabilityAlertDto>) => r.body as VulnerabilityAlertDto)
    );
  }

}
