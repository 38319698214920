import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { ApiService } from "./api.service";
import { environment } from "../../../environments/environment";
import { AdminDashboardDto, DashboardDto, ProfileModelDto, ReducedWorkspaceDto, WorkspaceModelDto } from "../api/models";
import { BehaviorSubject, Observable, filter, tap } from "rxjs";
import { Router } from "@angular/router";
import { InitService } from "./init.service";

export const WORKSPACE: string | null =
  location.host
    .replace(environment.appBaseUrl, "")
    .replace(/\-$/g, "")
    .split("-")[0] ?? null;

@Injectable({
  providedIn: "root",
})
export class CoreProvider {
  public readonly workspace: string | null = WORKSPACE;
  private _userData = new BehaviorSubject<ProfileModelDto | null>(null);
  private _dashboard = new BehaviorSubject<DashboardDto | AdminDashboardDto | null>(null);
  private _workspace = new BehaviorSubject<ReducedWorkspaceDto | null>(null);

  private _isFetchingUserData = false;
  private _isFetchingDashboard = false;
  private _isFetchingWorkspace = false;

  loggedUserRequiredAction;

  resourceRedirection: boolean = false;
  get roles(): string[] {
    if (!this.keycloak.isLoggedIn()) return [];
    return this.keycloak.getUserRoles();
  }

  constructor(
    public readonly keycloak: KeycloakService,
    public readonly initService: InitService,
    public readonly api: ApiService,
    public readonly router: Router,
  ) {
    if (!environment.production) window['core'] = this;
    this.initService.initCore(this);

    this.keycloak.keycloakEvents$.subscribe(async () => {
      if (this.keycloak.isLoggedIn()) this.getUserData();
    });
    if (this.keycloak.isLoggedIn()) this.getUserData();
  }

  async logOut() {
    await this.keycloak.logout();
  }

  isUserAdmin(): boolean {
    return this.keycloak.isLoggedIn() && this.keycloak.getUserRoles().includes('eve-admin');
  }

  getUserData(): Observable<ProfileModelDto | null> {
    if (!this._userData.value && !this._isFetchingUserData) {
      this._isFetchingUserData = true;
      this.fetchUserData().subscribe({
        complete: () => this._isFetchingUserData = false,
      });
    }
    return this._userData.asObservable().pipe(
      filter(data => data !== null)
    );
  }

  fetchUserData(): Observable<ProfileModelDto> {
    return this.api.me.meControllerMe().pipe(
      tap({
        next: (data: ProfileModelDto) => {console.log('datos del usuario', data); this._userData.next(data); this.loggedUserRequiredAction = data.requiredAction; this.initService.initUser()},
        error: (error: any) => {
          console.error('Error al obtener el perfil del usuario', error);
        }
      })
    );
  }

  getDashboardData(): Observable<DashboardDto | AdminDashboardDto | null> {
    if (!this._dashboard.value && !this._isFetchingDashboard) {
      this._isFetchingDashboard = true;
      this.fetchDashboardData().subscribe({
        complete: () => this._isFetchingDashboard = false,
      });
    }
    return this._dashboard.asObservable().pipe(
      filter(data => data !== null)
    );
  }

  fetchDashboardData(): Observable<AdminDashboardDto | DashboardDto> {
    if ( this.isUserAdmin()) {
      return this.api.me.meControllerAdminDashboard().pipe(
        tap({
          next: (data: AdminDashboardDto) => this._dashboard.next(data),
          error: (error: any) => {
            console.error('Error al obtener el dashboard del usuario', error);
          }
        })
      );
    } else {
      return this.api.me.meControllerDashboard().pipe(
        tap({
          next: (data: DashboardDto) => this._dashboard.next(data),
          error: (error: any) => {
            console.error('Error al obtener el dashboard del usuario', error);
          }
        })
      );
    }
  }
}
