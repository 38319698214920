import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-eve-toogle',
  templateUrl: './eve-toogle-component.component.html',
  styleUrls: ['./eve-toogle-component.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EveToogleComponent),
      multi: true
    }
  ]
})
export class EveToogleComponent implements ControlValueAccessor {
  @Input() formControl: FormControl;
  value: string = 'ET';  // Default value

  private onChange = (value: any) => {};
  private onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggle(): void {
    this.value = this.value === 'ET' ? 'LP' : 'ET';
    this.updateChanges();
  }

  updateChanges(): void {
    this.onChange(this.value);
  }
}
