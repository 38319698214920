import { Injectable } from "@angular/core";
import { NbSidebarService } from "@nebular/theme";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  sidebarVisible$ = new BehaviorSubject<boolean>(true);
  isTransition: boolean;

  canToogleSidebar: boolean = true;
  constructor(private sidebarService: NbSidebarService) {}

  toggleSidebarVisibility(visible: boolean, isTransition: boolean = false): void {
    this.sidebarVisible$.next(visible);
    this.isTransition = isTransition;
  }

}
