/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CampaignEventModelDto } from '../models/campaign-event-model-dto';

@Injectable({
  providedIn: 'root',
})
export class CampaignEventService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation campaignEventControllerGetCampaignEvents
   */
  static readonly CampaignEventControllerGetCampaignEventsPath = '/campaign-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignEventControllerGetCampaignEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerGetCampaignEvents$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CampaignEventModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignEventService.CampaignEventControllerGetCampaignEventsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignEventModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignEventControllerGetCampaignEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerGetCampaignEvents(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<CampaignEventModelDto>> {

    return this.campaignEventControllerGetCampaignEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignEventModelDto>>) => r.body as Array<CampaignEventModelDto>)
    );
  }

  /**
   * Path part for operation campaignEventControllerCreateCampaignEvent
   */
  static readonly CampaignEventControllerCreateCampaignEventPath = '/campaign-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignEventControllerCreateCampaignEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignEventControllerCreateCampaignEvent$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignEventModelDto
  }
): Observable<StrictHttpResponse<CampaignEventModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignEventService.CampaignEventControllerCreateCampaignEventPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignEventModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignEventControllerCreateCampaignEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignEventControllerCreateCampaignEvent(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignEventModelDto
  }
): Observable<CampaignEventModelDto> {

    return this.campaignEventControllerCreateCampaignEvent$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignEventModelDto>) => r.body as CampaignEventModelDto)
    );
  }

  /**
   * Path part for operation campaignEventControllerGetCampaignEventByUuid
   */
  static readonly CampaignEventControllerGetCampaignEventByUuidPath = '/campaign-event/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignEventControllerGetCampaignEventByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerGetCampaignEventByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignEventModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignEventService.CampaignEventControllerGetCampaignEventByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignEventModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignEventControllerGetCampaignEventByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerGetCampaignEventByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<CampaignEventModelDto> {

    return this.campaignEventControllerGetCampaignEventByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignEventModelDto>) => r.body as CampaignEventModelDto)
    );
  }

  /**
   * Path part for operation campaignEventControllerUpdateCampaignEvent
   */
  static readonly CampaignEventControllerUpdateCampaignEventPath = '/campaign-event/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignEventControllerUpdateCampaignEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignEventControllerUpdateCampaignEvent$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignEventModelDto
  }
): Observable<StrictHttpResponse<CampaignEventModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignEventService.CampaignEventControllerUpdateCampaignEventPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignEventModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignEventControllerUpdateCampaignEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignEventControllerUpdateCampaignEvent(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignEventModelDto
  }
): Observable<CampaignEventModelDto> {

    return this.campaignEventControllerUpdateCampaignEvent$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignEventModelDto>) => r.body as CampaignEventModelDto)
    );
  }

  /**
   * Path part for operation campaignEventControllerDeleteCampaignEvent
   */
  static readonly CampaignEventControllerDeleteCampaignEventPath = '/campaign-event/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignEventControllerDeleteCampaignEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerDeleteCampaignEvent$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignEventService.CampaignEventControllerDeleteCampaignEventPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignEventControllerDeleteCampaignEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignEventControllerDeleteCampaignEvent(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.campaignEventControllerDeleteCampaignEvent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
