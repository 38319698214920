import { Component, Input, OnInit } from '@angular/core';
import { ProfilesGroupedByPersonalityVarDto, ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-dashboard-area-chart',
  templateUrl: './dashboard-area-chart.component.html',
  styleUrls: ['./dashboard-area-chart.component.scss']
})
export class DashboardAreaChartComponent implements OnInit {
  @Input() colorScheme;
  @Input() graphData: Array<ProfilesGroupedByVulnerabilityLevelDto | ProfilesGroupedByPersonalityVarDto>;

  view: any[] = [300, 158];

  values: any[];
  // values = [
  //   {
  //     "name": "Vulnerabilidad",
  //     "series": [
  //       {
  //         "name": "Sem 1",
  //         "value": 40
  //       },
  //       {
  //         "name": "Sem 2",
  //         "value": 70
  //       },
  //       {
  //         "name": "Sem 3",
  //         "value": 36
  //       },
  //       {
  //         "name": "Sem 4",
  //         "value": 86
  //       },{
  //         "name": "Sem 5",
  //         "value": 25
  //       }

  //     ]
  //   },
  // ];

  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;
  constructor() { }

  ngOnInit() {
    if (this.graphData ) {
      this.checkValues();
    }
  }

  onSelect(event) {
  }

  checkValues() {
    this.values = [];
    this.graphData.map((itm: ProfilesGroupedByPersonalityVarDto) => {
      this.values.push({
        "name": "Vulnerabilidad",
        "series": [
          {
            "name": "Alta",
            "value": itm.highLevelTotalProfileCount
          },
          {
            "name": "Media-alta",
            "value": itm.midhighLevelTotalProfileCount
          },
          {
            "name": "Media-baja",
            "value": itm.midlowLevelTotalProfileCount
          },
          {
            "name": "Baja",
            "value": itm.lowLevelTotalProfileCount
          }
        ]
      })
    })
  }
}
