import {
  Injectable,
  Renderer2,
  RendererFactory2,
  TemplateRef,
} from "@angular/core";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrConfig,
  NbToastrService,
} from "@nebular/theme";

@Injectable({
  providedIn: "root",
})
export class ComponentsService {
  constructor(
    private readonly dialog: NbDialogService,
    private readonly toastService: NbToastrService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  private renderer: Renderer2;

  showToast(status, message, title) {
    const iconsMap = {
      success: "checkmark-circle-2-outline",
      danger: "close-circle-outline",
      primary: "alert-circle-outline",
      warning: "close-circle-outline",
    };
    const config: Partial<NbToastrConfig> = {
      status: status,
      destroyByClick: false,
      duration: 5000,
      hasIcon: !!iconsMap[status],
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
      icon: iconsMap[status],
      toastClass: 'toastClass'
    };

    this.toastService.show(message, title, config);
  }

  openModal(
    dialog: TemplateRef<any>,
    context?: any,
    closeOnBackdropClick?: boolean,
    cssClass?: string
  ): NbDialogRef<any> {
    const residualContainers = document.querySelectorAll(
      ".toastr-overlay-container.cdk-global-overlay-wrapper"
    );
    residualContainers.forEach((container) => {
      this.renderer.removeChild(document.body, container);
    });

    return this.dialog.open(dialog, {
      context: context,
      dialogClass: cssClass || "",
      closeOnEsc: false,
      hasBackdrop: true,
      closeOnBackdropClick: closeOnBackdropClick,
    });
  }
}
