import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { CoreProvider, WORKSPACE } from '../services/core.provider';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly core: CoreProvider,
  ) {
    super(router, core.keycloak);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!WORKSPACE)  {
      this.router.navigate(['/public/select-workspace'], { state: { emptyWorkspace: true } });
      return this.router.createUrlTree(['/public/select-workspace']);
    } else {
      console.warn('AuthGuard#canActivate called', this.core.keycloak.getKeycloakInstance());
      while (!this.core.keycloak.getKeycloakInstance()) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      return super.canActivate(route, state);;
    }
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      console.warn('AuthGuard#isAccessAllowed called - not authenticated');
      if (environment.isDemo && !location.href.includes('demoLogin=')) {
        console.warn('AuthGuard#isAccessAllowed called - not authenticated - redirecting to demologin');
        location.href = `${location.origin}/?demoLogin=new`;
      } else {
        await this.core.keycloak.login({
          redirectUri: (window.location.origin + state.url).replace('demoLogin=', 'demoRedirectLogin='),
        });
      }
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
