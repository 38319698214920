/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailableCampaignEventTypesDto } from '../models/available-campaign-event-types-dto';
import { Base64FileDto } from '../models/base-64-file-dto';
import { CampaignCheckResultsDto } from '../models/campaign-check-results-dto';
import { CampaignModelDto } from '../models/campaign-model-dto';
import { CampaignProfileModelDto } from '../models/campaign-profile-model-dto';
import { ExportableCampaignDataDto } from '../models/exportable-campaign-data-dto';
import { ExportableCampaignFormatsDto } from '../models/exportable-campaign-formats-dto';
import { ProfileModelDto } from '../models/profile-model-dto';
import { ProfilesMailAndLandingDto } from '../models/profiles-mail-and-landing-dto';

@Injectable({
  providedIn: 'root',
})
export class CampaignService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation campaignControllerGetCampaigns
   */
  static readonly CampaignControllerGetCampaignsPath = '/campaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaigns$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Campaign name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CampaignModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetCampaignsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaigns(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Campaign name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<CampaignModelDto>> {

    return this.campaignControllerGetCampaigns$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignModelDto>>) => r.body as Array<CampaignModelDto>)
    );
  }

  /**
   * Path part for operation campaignControllerCreateCampaign
   */
  static readonly CampaignControllerCreateCampaignPath = '/campaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerCreateCampaign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerCreateCampaign$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignModelDto
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerCreateCampaignPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerCreateCampaign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerCreateCampaign(params: {
    workspace?: string;
    context?: HttpContext
    body: CampaignModelDto
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerCreateCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

  /**
   * Path part for operation campaignControllerGetCampaignByUuid
   */
  static readonly CampaignControllerGetCampaignByUuidPath = '/campaign/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetCampaignByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetCampaignByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetCampaignByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerGetCampaignByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

  /**
   * Path part for operation campaignControllerUpdateCampaign
   */
  static readonly CampaignControllerUpdateCampaignPath = '/campaign/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerUpdateCampaign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerUpdateCampaign$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignModelDto
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerUpdateCampaignPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerUpdateCampaign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerUpdateCampaign(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: CampaignModelDto
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerUpdateCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

  /**
   * Path part for operation campaignControllerDeleteCampaign
   */
  static readonly CampaignControllerDeleteCampaignPath = '/campaign/{uuid}';

  /**
   * Deletes a campaign and its associated campaign tokens.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerDeleteCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerDeleteCampaign$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerDeleteCampaignPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a campaign and its associated campaign tokens.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerDeleteCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerDeleteCampaign(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.campaignControllerDeleteCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation campaignControllerGetAvailableCampaignsEventTypes
   */
  static readonly CampaignControllerGetAvailableCampaignsEventTypesPath = '/campaign/events/available-campaign-event-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetAvailableCampaignsEventTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetAvailableCampaignsEventTypes$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AvailableCampaignEventTypesDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetAvailableCampaignsEventTypesPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailableCampaignEventTypesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetAvailableCampaignsEventTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetAvailableCampaignsEventTypes(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<AvailableCampaignEventTypesDto> {

    return this.campaignControllerGetAvailableCampaignsEventTypes$Response(params).pipe(
      map((r: StrictHttpResponse<AvailableCampaignEventTypesDto>) => r.body as AvailableCampaignEventTypesDto)
    );
  }

  /**
   * Path part for operation campaignControllerCheckCampaignByUuid
   */
  static readonly CampaignControllerCheckCampaignByUuidPath = '/campaign/{uuid}/before-launch-check';

  /**
   * Checks if the campaign can be launched.
   *
   * Checks all campaign profiles and if there is some profile that has not either landing page or mail template return that profile email
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerCheckCampaignByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerCheckCampaignByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to launch by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignCheckResultsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerCheckCampaignByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignCheckResultsDto>;
      })
    );
  }

  /**
   * Checks if the campaign can be launched.
   *
   * Checks all campaign profiles and if there is some profile that has not either landing page or mail template return that profile email
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerCheckCampaignByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerCheckCampaignByUuid(params: {
    workspace?: string;

    /**
     * uuid to launch by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<CampaignCheckResultsDto> {

    return this.campaignControllerCheckCampaignByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignCheckResultsDto>) => r.body as CampaignCheckResultsDto)
    );
  }

  /**
   * Path part for operation campaignControllerLaunchCampaignByUuid
   */
  static readonly CampaignControllerLaunchCampaignByUuidPath = '/campaign/{uuid}/launch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerLaunchCampaignByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerLaunchCampaignByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to launch by
     */
    uuid: string;

    /**
     * Campaign profile uuid to launch by
     */
    campaignProfileUuid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerLaunchCampaignByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.query('campaignProfileUuid', params.campaignProfileUuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerLaunchCampaignByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerLaunchCampaignByUuid(params: {
    workspace?: string;

    /**
     * uuid to launch by
     */
    uuid: string;

    /**
     * Campaign profile uuid to launch by
     */
    campaignProfileUuid?: string;
    context?: HttpContext
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerLaunchCampaignByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

  /**
   * Path part for operation campaignControllerChangeCampaignStatusByUuid
   */
  static readonly CampaignControllerChangeCampaignStatusByUuidPath = '/campaign/{uuid}/change-status/{status}';

  /**
   * Changes a campaign status by it's uuid.
   *
   * If the new status is 'finalizada', associated campaignTokens and cron jobs will be removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerChangeCampaignStatusByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerChangeCampaignStatusByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to change status by
     */
    uuid: string;

    /**
     * new campaign status
     */
    status: 'borrador' | 'finalizada';

    /**
     * Campaign profile uuid to stop by
     */
    campaignProfileUuid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerChangeCampaignStatusByUuidPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.path('status', params.status, {});
      rb.query('campaignProfileUuid', params.campaignProfileUuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * Changes a campaign status by it's uuid.
   *
   * If the new status is 'finalizada', associated campaignTokens and cron jobs will be removed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerChangeCampaignStatusByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerChangeCampaignStatusByUuid(params: {
    workspace?: string;

    /**
     * uuid to change status by
     */
    uuid: string;

    /**
     * new campaign status
     */
    status: 'borrador' | 'finalizada';

    /**
     * Campaign profile uuid to stop by
     */
    campaignProfileUuid?: string;
    context?: HttpContext
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerChangeCampaignStatusByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

  /**
   * Path part for operation campaignControllerGetCampaignProfilesRelationByCampaignUuid
   */
  static readonly CampaignControllerGetCampaignProfilesRelationByCampaignUuidPath = '/campaign/{uuid}/campaign-profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetCampaignProfilesRelationByCampaignUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignProfilesRelationByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CampaignProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetCampaignProfilesRelationByCampaignUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetCampaignProfilesRelationByCampaignUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignProfilesRelationByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<CampaignProfileModelDto>> {

    return this.campaignControllerGetCampaignProfilesRelationByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignProfileModelDto>>) => r.body as Array<CampaignProfileModelDto>)
    );
  }

  /**
   * Path part for operation campaignControllerExportCsvCampaignInfoByCampaignUuid
   */
  static readonly CampaignControllerExportCsvCampaignInfoByCampaignUuidPath = '/campaign/{uuid}/export/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerExportCsvCampaignInfoByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportCsvCampaignInfoByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<StrictHttpResponse<Array<Base64FileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerExportCsvCampaignInfoByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Base64FileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerExportCsvCampaignInfoByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportCsvCampaignInfoByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<Array<Base64FileDto>> {

    return this.campaignControllerExportCsvCampaignInfoByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Base64FileDto>>) => r.body as Array<Base64FileDto>)
    );
  }

  /**
   * Path part for operation campaignControllerExportPdfCampaignInfoByCampaignUuid
   */
  static readonly CampaignControllerExportPdfCampaignInfoByCampaignUuidPath = '/campaign/{uuid}/export/pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerExportPdfCampaignInfoByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportPdfCampaignInfoByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<StrictHttpResponse<Base64FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerExportPdfCampaignInfoByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Base64FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerExportPdfCampaignInfoByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportPdfCampaignInfoByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<Base64FileDto> {

    return this.campaignControllerExportPdfCampaignInfoByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Base64FileDto>) => r.body as Base64FileDto)
    );
  }

  /**
   * Path part for operation campaignControllerExportHtmlCampaignInfoByCampaignUuid
   */
  static readonly CampaignControllerExportHtmlCampaignInfoByCampaignUuidPath = '/campaign/{uuid}/export/html';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerExportHtmlCampaignInfoByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportHtmlCampaignInfoByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<StrictHttpResponse<Base64FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerExportHtmlCampaignInfoByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Base64FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerExportHtmlCampaignInfoByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportHtmlCampaignInfoByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<Base64FileDto> {

    return this.campaignControllerExportHtmlCampaignInfoByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Base64FileDto>) => r.body as Base64FileDto)
    );
  }

  /**
   * Path part for operation campaignControllerExportJsonCampaignInfoByCampaignUuid
   */
  static readonly CampaignControllerExportJsonCampaignInfoByCampaignUuidPath = '/campaign/{uuid}/export/json';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerExportJsonCampaignInfoByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportJsonCampaignInfoByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<StrictHttpResponse<Base64FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerExportJsonCampaignInfoByCampaignUuidPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Base64FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerExportJsonCampaignInfoByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerExportJsonCampaignInfoByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<ExportableCampaignDataDto>
  }
): Observable<Base64FileDto> {

    return this.campaignControllerExportJsonCampaignInfoByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Base64FileDto>) => r.body as Base64FileDto)
    );
  }

  /**
   * Path part for operation campaignControllerGetExportableCampaignFormats
   */
  static readonly CampaignControllerGetExportableCampaignFormatsPath = '/campaign/export/exportable-campaign-formats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetExportableCampaignFormats()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetExportableCampaignFormats$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExportableCampaignFormatsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetExportableCampaignFormatsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportableCampaignFormatsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetExportableCampaignFormats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetExportableCampaignFormats(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<ExportableCampaignFormatsDto> {

    return this.campaignControllerGetExportableCampaignFormats$Response(params).pipe(
      map((r: StrictHttpResponse<ExportableCampaignFormatsDto>) => r.body as ExportableCampaignFormatsDto)
    );
  }

  /**
   * Path part for operation campaignControllerGetExportableCampaignData
   */
  static readonly CampaignControllerGetExportableCampaignDataPath = '/campaign/export/exportable-campaign-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetExportableCampaignData()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetExportableCampaignData$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ExportableCampaignDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetExportableCampaignDataPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExportableCampaignDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetExportableCampaignData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetExportableCampaignData(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<Array<ExportableCampaignDataDto>> {

    return this.campaignControllerGetExportableCampaignData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExportableCampaignDataDto>>) => r.body as Array<ExportableCampaignDataDto>)
    );
  }

  /**
   * Path part for operation campaignControllerAddExcelCampaignProfiles
   */
  static readonly CampaignControllerAddExcelCampaignProfilesPath = '/campaign/campaign-profiles/import-csv';

  /**
   * Format: profileEmail, landingPageName, mailTemplateName.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerAddExcelCampaignProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerAddExcelCampaignProfiles$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: Base64FileDto
  }
): Observable<StrictHttpResponse<Array<CampaignProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerAddExcelCampaignProfilesPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignProfileModelDto>>;
      })
    );
  }

  /**
   * Format: profileEmail, landingPageName, mailTemplateName.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerAddExcelCampaignProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerAddExcelCampaignProfiles(params: {
    workspace?: string;
    context?: HttpContext
    body: Base64FileDto
  }
): Observable<Array<CampaignProfileModelDto>> {

    return this.campaignControllerAddExcelCampaignProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignProfileModelDto>>) => r.body as Array<CampaignProfileModelDto>)
    );
  }

  /**
   * Path part for operation campaignControllerFastDataGeneration
   */
  static readonly CampaignControllerFastDataGenerationPath = '/campaign/campaign-profiles/fast-data-generation';

  /**
   * Receives a profiles list, and a set of names and calculates the correct mail template and landing page for each profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerFastDataGeneration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerFastDataGeneration$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: ProfilesMailAndLandingDto
  }
): Observable<StrictHttpResponse<Array<CampaignProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerFastDataGenerationPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CampaignProfileModelDto>>;
      })
    );
  }

  /**
   * Receives a profiles list, and a set of names and calculates the correct mail template and landing page for each profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerFastDataGeneration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerFastDataGeneration(params: {
    workspace?: string;
    context?: HttpContext
    body: ProfilesMailAndLandingDto
  }
): Observable<Array<CampaignProfileModelDto>> {

    return this.campaignControllerFastDataGeneration$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CampaignProfileModelDto>>) => r.body as Array<CampaignProfileModelDto>)
    );
  }

  /**
   * Path part for operation campaignControllerGetCampaignProfilesByCampaignUuid
   */
  static readonly CampaignControllerGetCampaignProfilesByCampaignUuidPath = '/campaign/{uuid}/profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerGetCampaignProfilesByCampaignUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignProfilesByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerGetCampaignProfilesByCampaignUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerGetCampaignProfilesByCampaignUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignControllerGetCampaignProfilesByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<Array<ProfileModelDto>> {

    return this.campaignControllerGetCampaignProfilesByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileModelDto>>) => r.body as Array<ProfileModelDto>)
    );
  }

  /**
   * Path part for operation campaignControllerSetCampaignProfilesByCampaignUuid
   */
  static readonly CampaignControllerSetCampaignProfilesByCampaignUuidPath = '/campaign/{uuid}/profiles';

  /**
   * Modifies profiles in a campaign.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignControllerSetCampaignProfilesByCampaignUuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerSetCampaignProfilesByCampaignUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<CampaignProfileModelDto>
  }
): Observable<StrictHttpResponse<CampaignModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignService.CampaignControllerSetCampaignProfilesByCampaignUuidPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CampaignModelDto>;
      })
    );
  }

  /**
   * Modifies profiles in a campaign.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `campaignControllerSetCampaignProfilesByCampaignUuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  campaignControllerSetCampaignProfilesByCampaignUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
    body: Array<CampaignProfileModelDto>
  }
): Observable<CampaignModelDto> {

    return this.campaignControllerSetCampaignProfilesByCampaignUuid$Response(params).pipe(
      map((r: StrictHttpResponse<CampaignModelDto>) => r.body as CampaignModelDto)
    );
  }

}
