import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="socials">
      <img class="footer-class h-100" src="assets/imgs/incibe_white_band.svg">
    </div>
  `,
})
export class FooterComponent {
}
