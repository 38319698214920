<div *ngIf="results" class="d-flex justify-center align-items-center w-100 h-100">
  <!-- Chart antiguo -->
  <!-- <ngx-charts-pie-chart
        [scheme]="colorScheme"
        [results]="results"
        [labels]="true"
        [explodeSlices]="false"
  >
  </ngx-charts-pie-chart> -->

  <!-- D3 Chart -->
  <div #chartContainer [id]="mainContainerId" (window:resize)="onResize()" class="w-100 h-100 d-flex align-items-end pieContainer"></div>
  <div id="tooltip-{{mainContainerId}}" class="tooltip-pie"></div>

</div>

<ng-template #textLegend></ng-template>