/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfileModelDto } from '../models/profile-model-dto';
import { RequiredActionsDto } from '../models/required-actions-dto';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation profileControllerGetProfiles
   */
  static readonly ProfileControllerGetProfilesPath = '/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerGetProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetProfiles$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerGetProfilesPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerGetProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetProfiles(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Profile name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<ProfileModelDto>> {

    return this.profileControllerGetProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileModelDto>>) => r.body as Array<ProfileModelDto>)
    );
  }

  /**
   * Path part for operation profileControllerCreateProfile
   */
  static readonly ProfileControllerCreateProfilePath = '/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerCreateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  profileControllerCreateProfile$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: ProfileModelDto
  }
): Observable<StrictHttpResponse<ProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerCreateProfilePath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerCreateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  profileControllerCreateProfile(params: {
    workspace?: string;
    context?: HttpContext
    body: ProfileModelDto
  }
): Observable<ProfileModelDto> {

    return this.profileControllerCreateProfile$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileModelDto>) => r.body as ProfileModelDto)
    );
  }

  /**
   * Path part for operation profileControllerGetProfileByUuid
   */
  static readonly ProfileControllerGetProfileByUuidPath = '/profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerGetProfileByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetProfileByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerGetProfileByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerGetProfileByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetProfileByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<ProfileModelDto> {

    return this.profileControllerGetProfileByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileModelDto>) => r.body as ProfileModelDto)
    );
  }

  /**
   * Path part for operation profileControllerUpdateProfile
   */
  static readonly ProfileControllerUpdateProfilePath = '/profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerUpdateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  profileControllerUpdateProfile$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: ProfileModelDto
  }
): Observable<StrictHttpResponse<ProfileModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerUpdateProfilePath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerUpdateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  profileControllerUpdateProfile(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: ProfileModelDto
  }
): Observable<ProfileModelDto> {

    return this.profileControllerUpdateProfile$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileModelDto>) => r.body as ProfileModelDto)
    );
  }

  /**
   * Path part for operation profileControllerDeleteProfile
   */
  static readonly ProfileControllerDeleteProfilePath = '/profile/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerDeleteProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerDeleteProfile$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerDeleteProfilePath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerDeleteProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerDeleteProfile(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.profileControllerDeleteProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation profileControllerGetRequiredActions
   */
  static readonly ProfileControllerGetRequiredActionsPath = '/profile/actions/required-actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerGetRequiredActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetRequiredActions$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<RequiredActionsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerGetRequiredActionsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredActionsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerGetRequiredActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetRequiredActions(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<RequiredActionsDto> {

    return this.profileControllerGetRequiredActions$Response(params).pipe(
      map((r: StrictHttpResponse<RequiredActionsDto>) => r.body as RequiredActionsDto)
    );
  }

  /**
   * Path part for operation profileControllerGetUserProfiles
   */
  static readonly ProfileControllerGetUserProfilesPath = '/profile/eve-user/profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileControllerGetUserProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetUserProfiles$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ProfileModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ProfileControllerGetUserProfilesPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `profileControllerGetUserProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileControllerGetUserProfiles(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<ProfileModelDto>> {

    return this.profileControllerGetUserProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileModelDto>>) => r.body as Array<ProfileModelDto>)
    );
  }

}
