/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DepartmentModelDto } from '../models/department-model-dto';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation departmentControllerGetDepartments
   */
  static readonly DepartmentControllerGetDepartmentsPath = '/department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departmentControllerGetDepartments()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerGetDepartments$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DepartmentModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentService.DepartmentControllerGetDepartmentsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepartmentModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `departmentControllerGetDepartments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerGetDepartments(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    context?: HttpContext
  }
): Observable<Array<DepartmentModelDto>> {

    return this.departmentControllerGetDepartments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DepartmentModelDto>>) => r.body as Array<DepartmentModelDto>)
    );
  }

  /**
   * Path part for operation departmentControllerCreateDepartment
   */
  static readonly DepartmentControllerCreateDepartmentPath = '/department';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departmentControllerCreateDepartment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  departmentControllerCreateDepartment$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: DepartmentModelDto
  }
): Observable<StrictHttpResponse<DepartmentModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentService.DepartmentControllerCreateDepartmentPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepartmentModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `departmentControllerCreateDepartment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  departmentControllerCreateDepartment(params: {
    workspace?: string;
    context?: HttpContext
    body: DepartmentModelDto
  }
): Observable<DepartmentModelDto> {

    return this.departmentControllerCreateDepartment$Response(params).pipe(
      map((r: StrictHttpResponse<DepartmentModelDto>) => r.body as DepartmentModelDto)
    );
  }

  /**
   * Path part for operation departmentControllerGetDepartmentByUuid
   */
  static readonly DepartmentControllerGetDepartmentByUuidPath = '/department/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departmentControllerGetDepartmentByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerGetDepartmentByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DepartmentModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentService.DepartmentControllerGetDepartmentByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepartmentModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `departmentControllerGetDepartmentByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerGetDepartmentByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<DepartmentModelDto> {

    return this.departmentControllerGetDepartmentByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<DepartmentModelDto>) => r.body as DepartmentModelDto)
    );
  }

  /**
   * Path part for operation departmentControllerUpdateDepartment
   */
  static readonly DepartmentControllerUpdateDepartmentPath = '/department/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departmentControllerUpdateDepartment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  departmentControllerUpdateDepartment$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: DepartmentModelDto
  }
): Observable<StrictHttpResponse<DepartmentModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentService.DepartmentControllerUpdateDepartmentPath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepartmentModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `departmentControllerUpdateDepartment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  departmentControllerUpdateDepartment(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: DepartmentModelDto
  }
): Observable<DepartmentModelDto> {

    return this.departmentControllerUpdateDepartment$Response(params).pipe(
      map((r: StrictHttpResponse<DepartmentModelDto>) => r.body as DepartmentModelDto)
    );
  }

  /**
   * Path part for operation departmentControllerDeleteDepartment
   */
  static readonly DepartmentControllerDeleteDepartmentPath = '/department/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departmentControllerDeleteDepartment()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerDeleteDepartment$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentService.DepartmentControllerDeleteDepartmentPath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `departmentControllerDeleteDepartment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departmentControllerDeleteDepartment(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.departmentControllerDeleteDepartment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
