/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LandingPageModelDto } from '../models/landing-page-model-dto';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation landingPageControllerGetLandingPages
   */
  static readonly LandingPageControllerGetLandingPagesPath = '/landing-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `landingPageControllerGetLandingPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerGetLandingPages$Response(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Landing Page name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LandingPageModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LandingPageService.LandingPageControllerGetLandingPagesPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LandingPageModelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `landingPageControllerGetLandingPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerGetLandingPages(params?: {
    workspace?: string;

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * Landing Page name
     */
    name?: string;
    context?: HttpContext
  }
): Observable<Array<LandingPageModelDto>> {

    return this.landingPageControllerGetLandingPages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LandingPageModelDto>>) => r.body as Array<LandingPageModelDto>)
    );
  }

  /**
   * Path part for operation landingPageControllerCreateLandingPage
   */
  static readonly LandingPageControllerCreateLandingPagePath = '/landing-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `landingPageControllerCreateLandingPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  landingPageControllerCreateLandingPage$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: LandingPageModelDto
  }
): Observable<StrictHttpResponse<LandingPageModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, LandingPageService.LandingPageControllerCreateLandingPagePath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LandingPageModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `landingPageControllerCreateLandingPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  landingPageControllerCreateLandingPage(params: {
    workspace?: string;
    context?: HttpContext
    body: LandingPageModelDto
  }
): Observable<LandingPageModelDto> {

    return this.landingPageControllerCreateLandingPage$Response(params).pipe(
      map((r: StrictHttpResponse<LandingPageModelDto>) => r.body as LandingPageModelDto)
    );
  }

  /**
   * Path part for operation landingPageControllerGetLandingPageByUuid
   */
  static readonly LandingPageControllerGetLandingPageByUuidPath = '/landing-page/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `landingPageControllerGetLandingPageByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerGetLandingPageByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LandingPageModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, LandingPageService.LandingPageControllerGetLandingPageByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LandingPageModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `landingPageControllerGetLandingPageByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerGetLandingPageByUuid(params: {
    workspace?: string;

    /**
     * uuid to filter by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<LandingPageModelDto> {

    return this.landingPageControllerGetLandingPageByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<LandingPageModelDto>) => r.body as LandingPageModelDto)
    );
  }

  /**
   * Path part for operation landingPageControllerUpdateLandingPage
   */
  static readonly LandingPageControllerUpdateLandingPagePath = '/landing-page/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `landingPageControllerUpdateLandingPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  landingPageControllerUpdateLandingPage$Response(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: LandingPageModelDto
  }
): Observable<StrictHttpResponse<LandingPageModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, LandingPageService.LandingPageControllerUpdateLandingPagePath, 'put');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LandingPageModelDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `landingPageControllerUpdateLandingPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  landingPageControllerUpdateLandingPage(params: {
    workspace?: string;

    /**
     * uuid to update by
     */
    uuid: string;
    context?: HttpContext
    body: LandingPageModelDto
  }
): Observable<LandingPageModelDto> {

    return this.landingPageControllerUpdateLandingPage$Response(params).pipe(
      map((r: StrictHttpResponse<LandingPageModelDto>) => r.body as LandingPageModelDto)
    );
  }

  /**
   * Path part for operation landingPageControllerDeleteLandingPage
   */
  static readonly LandingPageControllerDeleteLandingPagePath = '/landing-page/{uuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `landingPageControllerDeleteLandingPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerDeleteLandingPage$Response(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LandingPageService.LandingPageControllerDeleteLandingPagePath, 'delete');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `landingPageControllerDeleteLandingPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  landingPageControllerDeleteLandingPage(params: {
    workspace?: string;

    /**
     * uuid to delete by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.landingPageControllerDeleteLandingPage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
