import { NgModule } from '@angular/core';
import { NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule, NbProgressBarModule, NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTagModule, NbTooltipModule } from '@nebular/theme';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EveToogleComponent } from './eve-toogle-component/eve-toogle-component.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { ConfirmWorkspaceModalComponent } from './confirm-workspace-modal/confirm-workspace-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TabTableConfirmComponent } from './tab-table-confirm/tab-table-confirm.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';


const COMPONENTS = [
  EveToogleComponent,
  PaginationComponent,
  ConfirmWorkspaceModalComponent,
  TabTableConfirmComponent,
  BasicModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbMenuModule,
    NbButtonModule,
    NbActionsModule,
    NbAlertModule,
    NbSelectModule,
    FormsModule,
    NbTabsetModule,
    NbSpinnerModule,
    NbIconModule,
    NbInputModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbCardModule,
    NbTagModule,
    NbMenuModule,
    NbProgressBarModule,
    NbButtonModule,
    NgxChartsModule,
    NbAlertModule,
    NbAccordionModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbIconModule,
    NgxDatatableModule,
    CommonModule,
    NgxDatatableModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [
  ],
  exports: [...COMPONENTS]
})
export class GeneralComponentsModule {
}
