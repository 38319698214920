import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreProvider } from '../../../../services/services/core.provider';
import { Pagination } from '../../../../services/services/utils/pagination.interface';
import { PaginationService } from '../../../../services/services/pagination.service';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {

  @Output("refreshPage") refreshPage: EventEmitter<any> = new EventEmitter();
  selectedPage: number;
  constructor(
    public readonly core: CoreProvider, public readonly pagination: PaginationService
  ) { }

  ngOnInit() {

  }

  setPage(pageNumber) {
    this.pagination.setPage({ offset: pageNumber });
    this.selectedPage = pageNumber;
    this.pagination.setPage(pageNumber);
    this.refreshPage.emit(this.pagination.page);
  }

  onPageSelect(page: number) {
    this.setPage(page);
  }

  prevPage() {
    if (this.pagination.prevPage()) {
      this.refreshPage.emit(this.pagination.page);
    }
  }

  nextPage() {
    if (this.pagination.nextPage()) {
      this.refreshPage.emit(this.pagination.page);
    }
  }

  updatePage() {
    this.refreshPage.emit(this.pagination.page);
  }
}
